import { createApp, computed } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/index.scss';
import '@/styles/color/index.scss';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import * as echarts from 'echarts';

// 国际化
import { createI18n } from 'vue-i18n';
import messages from '@/language/index';

//阿里图标
// import "@/components/svg/svg";
// import icons from "@/components/svg/svg.vue";
// import VueGtag from "vue-gtag";
import VueGtag from 'vue-gtag-next';

import { DatePicker, Progress } from 'ant-design-vue';
import { getMatchLanguage, getSystemAndBrowser } from '@/utils/format';
import { clickOutside } from './utils/directive';

// 首次进入时根据当前系统语言去匹配语言字符串
const matchLanguage = getMatchLanguage();
store.commit('setCurrentLang', matchLanguage);

const i18n = createI18n({
  legacy: false, //处理报错Uncaught (in promise) SyntaxError: Not available in legacy mode (at message-compiler.esm-bundler.js:54:19)
  locale: store.state.currentLang || 'en', //默认显示的语言
  messages,
});

(window as any).myI18n = i18n;
// function useTable(app: any) {
//   app.use(VXETable);

//   // 给 vue 实例挂载内部对象，例如：
//   // app.config.globalProperties.$XModal = VXETable.modal
//   // app.config.globalProperties.$XPrint = VXETable.print
//   // app.config.globalProperties.$XSaveFile = VXETable.saveFile
//   // app.config.globalProperties.$XReadFile = VXETable.readFile
// }

const app = createApp(App);

// 自定义指令
clickOutside(app);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// let equipment = "";
// let userAgentInfo = navigator.userAgent;
// if (/macintosh|mac os x/i.test(userAgentInfo)) {
//   equipment = "MAC";
// } else if (
//   userAgentInfo.indexOf("Android") > -1 ||
//   userAgentInfo.indexOf("Adr") > -1
// ) {
//   equipment = "Android";
// } else if (!!userAgentInfo.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
//   equipment = "iOS";
// } else {
//   equipment = "Win";
// }

// const systemAndBrowser = getSystemAndBrowser();
// console.log("systemAndBrowser", systemAndBrowser);

/**
 * 在vue2.x中，一般直接new vue()实例化即可
 * 在vue3.x中，通过createApp来返回一个实例，而且可以链式操作
 */
app.config.globalProperties.$echarts = echarts; //绑定实例

app.use(DatePicker);
app.use(Progress);

// let isPurchased = "UnPurchased";
// if (store.state.userInfo.id) {
//   // 已登陆
//   if (store.state.subscripts.expires_at) {
//     // 已购买会员
//     isPurchased = "Purchased";
//   } else {
//     isPurchased = "UnPurchased";
//   }
// } else {
//   // 未登录
//   isPurchased = "Normal";
// }

// const curLang = store.state.currentLang;

// const demoStr = store.state.isDemo ? "Demo" : "NoDemo";

app
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(VueGtag, {
    appName: 'WhatsApp',
    property: [
      {
        id: 'G-G3VEK3RWDJ',
      },
    ],
  })
  .use(i18n)
  // .use(useTable)
  // .component("icons", icons)
  .mount('#app');
//     const app=createApp(App);
// app.component('icons', icons)
export default i18n;
