export const DE = {
  chat: {
    pictureText: '[Bild]',
    videoText: '[Video]',
    audioText: '[Audio]',
    contactCard: '[Kontaktkarte]',
    location: '[Standort]',
    other: '[Andere]',
    all: 'Alle',
    today: 'Heute',
    yesterday: 'Gestern',
    last7Days: 'Letzte 7 Tage',
    last30Days: 'Letzte 30 Tage',
    customRange: 'Benutzerdefinierten Bereich auswählen',
    cancel: 'Abbrechen',
    apply: 'Anwenden',
    pushNotify: 'Push-Benachrichtigungen',
    numbers: 'Nummern',
    emails: 'E-Mails',
    outgoingCalls: 'Ausgehende Anrufe',
    incomingCalls: 'Eingehende Anrufe',
    missedCalls: 'Verpasste Anrufe',
    answeredCalls: 'Beantwortete Anrufe',
    noAnswer: 'Keine Antwort',
    canceledCalls: 'Abgebrochene Anrufe',
    loadingText: 'Wird geladen, bitte warten Sie',
    noMoreText: 'Es gibt keine weiteren Inhalte',
    noInfoText:
      'Keine Ereignisinformationen, bitte prüfen Sie später noch einmal.',
    noAddressText:
      'Keine Addressbuchinformationen, bitte prüfen Sie später noch einmal',
    noCallText:
      'Kein Anruferprofokoll, bitte überprüfen Sie es später noch einmal',

    dateSelect: 'Datum wählen',
    sentFile: 'Eine Datei gesendet',
    sentAudio: 'Audio gesendet',
    unGetFile: 'Datei nicht abrufbar',
    unGetAudio: 'Audio nicht abrufbar',

    syncFailed: 'Fehler bei der Datensynchronisierung!',
    syncSuccessed: 'Datensynchronisation erfolgreich!',
    unknownContact: 'Unbekannter Kontakt',
    noSyncNotice1: 'Die Daten wurden seit mehr als ',
    noSyncNotice2: '24 Stunden ',
    noSyncNotice3:
      'nicht mehr synchronisiert. Vergewissern Sie sich, dass das Zieltelefon eingeschaltet und mit dem Internet verbunden ist, und dass die APP "System Service" normal läuft.',
    sync: 'Sync',
    startDate: 'Anfangsdatum',
    endDate: 'Enddatum',
    noSupportToast: 'Die Datumsfilterung wird in der Demo nicht unterstützt.',
    unknowVideo:
      'Video. Details sind aufgrund der Datenschutzrichtlinie von Instagram nicht verfügbar.',
    unknowImage:
      'Bild. Details sind aufgrund der Datenschutzrichtlinie von Instagram nicht verfügbar.',
    unknowAudio:
      'Sprachnachricht. Details sind aufgrund der Datenschutzrichtlinie von Instagram nicht verfügbar.',
    outgoingVoice: 'Ausgehender Audioanruf',
    incomingVoice: 'Eingehender Audioanruf',
    outgoingVideo: 'Ausgehender Videoanruf',
    incomingVideo: 'Eingehender Videoanruf',
  },
  user: {
    userProfile: 'Benutzerprofil',
    usesrname: 'Benutzername:',
    edit: 'Bearbeiten',
    email: 'E-Mail:',
    permission: 'Informationen zur Berechtigung',
    bounded: 'Gebundene(s) Gerät(e):',
    restDevice: 'Verbleibende(s) verfügbare(s) Gerät(e):',
    expire: 'Ablaufdatum:',
    expired: '(Abgelaufen)',
    logout: 'Abmelden',
    changename: 'Benutzernamen ändern',
    save: 'speichern',
    inputname: 'Bitte geben Sie einen Benutzernamen ein',
    nameNull:
      'Das Format des Benutzernamens ist fehlerhaft, bitte geben Sie ihn erneut ein',
    networkError:
      'Die Änderung des Benutzernamens ist fehlgeschlagen, bitte überprüfen Sie Ihr Netzwerk',
    nameSuccess: 'Benutzername erfolgreich geändert',
    changeavatar: 'Avatar ändern',
    loading: 'Wird geladen...',
    changeAvatar: 'Ändern',
    supported:
      'Unterstützt die Formate jpg, jpeg, png, und die Größe darf 2M nicht überschreiten.',
    maxsize: 'Die Bildgröße darf 2MB nicht überschreiten',
    avatarError:
      'Das Ändern des Avatars ist fehlgeschlagen, bitte überprüfen Sie Ihr Netzwerk',
    avatarSuccess: 'Avatar erfolgreich geändert',
    imgError: 'Bild-Fehler',
    imageType: 'Bildtyp nicht unterstützt',
    nameEmpty: 'Benutzername kann nicht leer sein',
  },
  leftTab: {
    device: 'Gerät',
    unbound: 'Ungebundene(s) Gerät(e)',
    addDevice: 'Gerät hinzufügen',
    dashboard: 'Dashboard',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Bitte verbinden Sie zuerst ein Gerät',
    payRemind: 'Bitte erst kaufen',
    purchase: 'Jetzt kaufen',
    unboundName: 'Gerät jetzt binden',
    toBuy: 'Gerät kaufen',
  },
  guide: {
    title: 'Bitte verbinden Sie Ihr Gerät',
    oneT: '1. Zielgerät abrufen',
    oneContent:
      'Um das Gerät zu überwachen, müssen Sie das Zielüberwachungsgerät für etwa 10 Minuten abrufen.',
    twoT: '2. Herunterladen und Installieren der App',
    twoContent1: 'Geben Sie',
    twoContent2:
      'in den Browser des Zielgeräts ein, laden Sie dann die App herunter und installieren Sie sie.',
    threeT: '3. Geräteberechtigungen einrichten',
    threeContent:
      'Befolgen Sie die Anweisungen in der App, um die Geräteberechtigungen einzurichten. Nach der Einrichtung können Sie das Gerät überwachen.',
    tips1:
      "Um die Tarnung zu gewährleisten, wird die App als Systemsoftware mit dem Namen 'System Service' getarnt.",
    tips2:
      'Um Inkognito zu gewährleisten, können Sie nach erfolgreicher Installation der App Browsereinträge und App-Installationspakete löschen.',
    tips3:
      "App als 'System Service' auf dem Desktop angezeigt. Sie können sie in einem Ordner verstecken, um einen besseren Stealth-Effekt zu erzielen.",
    tips41:
      'Um sicherzustellen, dass Sie die App schneller installieren und ihre Berechtigungen einrichten können, können Sie sich zunächst die ',
    tips42: 'ansehen, um zu erfahren, wie das geht.',
    detail: 'die ausführlichen Anleitungen',
    tips5:
      ' Wenn die gebundene Geräteberechtigung abgelaufen ist, können Sie die Gerätebindung ändern und sie an die Berechtigung binden, um weiterhin Daten zu sammeln.',
    rebound: 'Klicken Sie auf , um die Gerätebindung zu ändern',
  },
  dashboard: {
    contact: 'Top-Kontakte in WhatsApp (innerhalb von 7 Tagen)',
    call: 'Top-Anrufe in WhatsApp',
    view: 'Alle anzeigen',
    account: 'Konto',
    id: 'Konto-ID:',
    expire: 'Ablaufdatum:',
    bound: 'Gebundenes Gerät:',
    restDevice: 'Verbeibende(s) Gerät(e):',
    target: 'Über das Zielgerät',
    brand: 'Marke:',
    model: 'Modell:',
    version: 'Android-Version:',
    battery: 'Akku:',
    wifi: 'Wi-Fi:',
    appversion: 'App-Version:',
    update: 'Zuletzt aktualisiert:',
    blank: 'Keine aktuelle Aktivität',
    unbindSuc: 'Entbinden gelungen!',
    unbindFail: 'Fehler beim Entbinden!!',
    ReboundSuc: 'Gerätebindung ändern erfolgreich!',
    ReboundFail: 'Gerätebindung ändern fehlgeschlagen!',
    noPlace: 'Kein Gerät zum Ändern der Bindung!',
    beExpOne: 'Ihre Geräteberechtigung läuft in',
    beExpTwo: 'Tagen',
    beExpThree:
      'ab, und die überwachten Gerätedaten werden nach Ablauf nicht mehr synchronisiert. ',
    expiredOne:
      'Ihre Geräteberechtigung ist abgelaufen. Die zuvor gesammelten Daten werden nach',
    expiredTwo:
      'gelöscht. Nach der Erneuerung werden die überwachten Gerätedaten weiterhin synchronisiert. ',
    renew: 'Jetzt erneuern',

    insContact: 'Top-Kontakte auf Instagram (innerhalb von 7 Tagen)',
    insCall: 'Top-Anrufe auf Instagram',
  },
  payPage: {
    title: 'Famikeep Preise & Pläne',
    dec: 'WhatsApp mit Famikeep zu überwachen ist einfacher als Sie denken!',
    bigSave: 'GROSSES SPAREN',
    one: '1-Monats-Plan',
    three: '3-Monats-Plan',
    year: '1-Jahres-Plan',
    toBuy: 'Jetzt Kaufen',
    auto: 'Auto Erneuerung',
    cancelAny: 'Stornieren jederzeit',
    update: 'Automatisches Aktualisieren der Daten alle fünf Minuten',
    collect: 'Alle Unterhaltungen sammeln',
    interaction: 'Bessere interaktive Erfahrung',
    hide: 'Stealth-Modus',
  },
  pop: {
    changeBound: 'Gerätebindung ändern',
    reboundText1: 'Sind Sie sicher, dass Sie die Gerätebindung',
    reboundText2:
      'ändern und an die ungebundene Geräteerlaubnis binden müssen, um weiterhin Daten zu sammeln (Ablaufdatum:',
    reboundText3: ')?',
    noRebound: 'Keine Gerätezulassung verfügbar. Bitte kaufen Sie es zuerst.',
    goPay: 'Jetzt kaufen',
    reboundList: 'Gebundenes Gerät',
    reboundDec:
      'Die folgende Geräteberechtigung ist abgelaufen. Wählen Sie ein Gerät aus und binden Sie es an die derzeit ungebundene Geräteerlaubnis, um die Datenerfassung fortzusetzen.',
    rebounding: 'Ändern der Gerätebindung...',
    reboundFail1: 'Die Gerätebindung',
    reboundFail2:
      'konnte nicht geändert werden. Möchten Sie es noch einmal versuchen?',
    tryAgain: 'einmal versuchen',
    unbindTitle: 'Gerät entbinden',
    unbindText1: 'Sind Sie sicher, dass Sie das Gerät',
    unbindText2:
      'entbinden wollen? Alle gesammelten Dateninformationen werden nach dem Aufheben der Bindung gelöscht.',
    remind: 'Verfallsaufforderung',
    expiredPay: 'Erneuern',
    cancel: 'Abbrechen',
    yes: 'Ja',
    no: 'Nein',
  },
  navTop: {
    langTitle: 'Sprache ändern',
  },
  famikeep200: {
    Calls: 'Anrufe',
    Messages: 'Nachrichten',
    Contacts: 'Kontakte',
    Photos: 'Fotos',
    Applications: 'Anwendungen',
    'Compared to yesterday': 'Im Vergleich zu Gestern',
    'All locations': 'Alle Standorte',
    'Weekly average usage time': 'Wöchentliche durchschnittliche Nutzungsdauer',
    'Floating comparison with last week':
      'Fließender Vergleich mit letzter Woche',
    Average: 'Durchschnitt',
    Mon: 'Mo.',
    Tue: 'Di.',
    Wed: 'Mi.',
    Thu: 'Do.',
    Fri: 'Fr.',
    Sat: 'Sa.',
    Sun: 'So.',
    'Most used applications': 'Meistgenutzte Anwendungen',
    Geolocation: 'Geolokalisierung',
    'Last updated': 'Zuletzt aktualisiert',
    'Geolocation permission':
      "Geolokalisierungsberechtigung für die Anwendung 'Systemdienst' ist deaktiviert, Standort konnte nicht abgerufen werden",
    Geofence: 'Geofence',
    'Create a geofence':
      'Erstellen Sie einen Geofence, um per E-Mail benachrichtigt zu werden, wenn Ihr Kind den angegebenen Bereich betritt oder verlässt.',
    'enters/exits': 'den angegebenen Bereich betritt oder verlässt.',
    'Create a new geofence': 'Erstellen Sie einen neuen Geofence',
    'Geofence name': 'Geofence-Name',
    'Set geofence location': 'Standort für Geofence festlegen',
    'Set radius': 'Radius festlegen',
    'Set monitoring time': 'Festlegen der Überwachungszeit',
    'please choose': 'bitte auswählen',
    Daily: 'Täglich',
    'Every Monday': 'Jeden Montag',
    'Every Tuesday': 'Jeden Dienstag',
    'Every Wednesday': 'Jeden Mittwoch',
    'Every Thursday': 'Jeden Donnerstag',
    'Every Friday': 'Jeden Freitag',
    'Every Saturday': 'Jeden Samstag',
    'Every Sunday': 'Jeden Sonntag',
    'Send alerts via email': 'Benachrichtigungen per E-Mail senden',
    'We will send alert emails to the registered email':
      'Wir senden Warn-E-Mails an die registrierte E-Mail-Adresse',
    Save: 'Speichern',
    'Please enter a geofence name': 'Bitte geben Sie einen Geofence-Namen ein',
    'Please select a geofence location':
      'Bitte wählen Sie einen Geofence-Standort',
    Entered: 'Eingegeben',
    Exited: 'Verlassen',
    Edit: 'Bearbeiten',
    Delete: 'Löschen',
    'Are you sure you want to delete':
      'Sind Sie sicher, dass Sie den Geofence xxx löschen wollen? Entsprechende Überwachungsdaten werden ebenfalls gelöscht',
    'Are you sure you want to close':
      'Sind Sie sicher, dass Sie den Geofence schließen möchten? Sie erhalten keine E-Mail-Benachrichtigungen, wenn Sie den Geofence betreten oder verlassen',
    'Call history': 'Anrufliste',
    Status: 'Status',
    Number: 'Nummer',
    Duration: 'Dauer',
    Date: 'Datum',
    incoming: 'Eingehender Anruf',
    outgoing: 'Ausgehender Anruf',
    missed: 'Verpasster Anruf',
    'no answered': 'Keine Antwort',
    Address: 'Adresse',
    'Added time': 'Hinzugefügte Zeit',
    'Sort by name': 'Sortieren nach Name',
    'Sort by time': 'Sortieren nach Zeit',
    'Please note that photos':
      'Bitte beachten Sie, dass die Fotos nur hochgeladen werden, wenn das Gerät mit Wi-Fi verbunden ist.',
    Album: 'Album',
    'Select all photos on the current page':
      'Alle Fotos auf der aktuellen Seite auswählen',
    'Previous page': 'Vorherige Seite',
    'Next page': 'Nächste Seite',
    Back: 'Zurück',
    'confirm to delete':
      'Zum Löschen bestätigen? Daten können nach dem Löschen nicht wiederhergestellt werden',
    Download: 'Herunterladen',
    Calendar: 'Kalender',
    Title: 'Titel',
    Description: 'Beschreibung',
    Location: 'Ort',
    'Start time': 'Startzeit',
    'End time': 'Endzeit',
    'Installed applications': 'Installierte Anwendungen',
    'All applications': 'Alle Anwendungen',
    Name: 'Name',
    Version: 'Version',
    Size: 'Größe',
    'Installation date': 'Installationsdatum',
    'Disable applications': 'Anwendungen deaktivieren',
    'Seted disable rules': 'Deaktivierungsregeln festlegen',
    'Undisabled applications': 'Nicht deaktivierte Anwendungen',
    'Click the button below':
      'Klicken Sie auf die Schaltfläche unten, um eine Regel zum Deaktivieren von Anwendungen zu erstellen, und wählen Sie die Anwendungen aus, die Sie deaktivieren möchten',
    'Set disable rules': 'Deaktivierungsregeln festlegen',
    'Disable permanently': 'Dauerhaft deaktivieren',
    'Disable ': 'Deaktivieren ',
    'Custom disable time': 'Benutzerdefinierte Deaktivierungszeit',
    'Disable period': 'Zeitraum der Deaktivierung',
    From: 'Von',
    To: 'bis',
    Total: 'Insgesamt',
    Weekly: 'wöchentlich',
    Time: 'Zeit',
    'Select the applications you want to disable':
      'Wählen Sie die Anwendungen, die Sie deaktivieren möchten',
    'Please select an application below':
      'Bitte wählen Sie unten eine Anwendung aus',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Sind Sie sicher, dass Sie löschen möchten? Die deaktivierten Anwendungen unter dieser Regel werden nicht mehr betroffen sein.',
    'Disable options:': 'Optionen deaktivieren:',
    'one Disable permanently':
      '1. Dauerhaft deaktivieren: Deaktiviert die Anwendung vollständig. Ihr Kind kann die deaktivierten Anwendungen nicht mehr verwenden, bis Sie die Deaktivierung aufheben.',
    'two Custom disable time':
      '2. Benutzerdefinierte Deaktivierungszeit: Löst die Deaktivierungsregel nur während bestimmter Zeitspannen aus, und Ihr Kind kann die Anwendungen außerhalb dieser Zeiten normal nutzen.',
    'You can only choose one of the two setting options':
      'Sie können nur eine der beiden Einstellungsoptionen wählen',
    complete: 'vollständig',
    'Screen usage time': 'Bildschirmnutzungszeit',
    'Screen Usage Time for xxx': 'Bildschirmzeit xxx',
    'Frequently used applications': 'Häufig verwendete Anwendungen',
    Frequency: 'Häufigkeit',
    'Usage time': 'Verwendungszeit',
    'Screen disable': 'Bildschirm sperren',
    'Turn on the switch and set the screen lock duration.':
      'Schalten Sie den Schalter ein und legen Sie die Dauer der Bildschirmsperre fest.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'Der Bildschirm des Geräts Ihres Kindes wird deaktiviert und nach Ablauf der eingestellten Zeit wieder aktiviert.',
    'Disable all screen activities': 'Alle Bildschirmaktivitäten deaktivieren',
    'Set screen disable duration': 'Einstellen der Dauer der Bildschirmsperre',
    'You can lift the disablement at any time':
      'Sie können die Deaktivierung jederzeit rückgängig machen.',
    'Remaining screen disable time on the target device':
      'Verbleibende Zeit für die Bildschirmsperre auf dem Zielgerät',
    'Are you sure you want to lift the screen disablement now?':
      'Sind Sie sicher, dass Sie die Bildschirmsperre jetzt aufheben möchten?',
    Yes: 'Ja',
    'This account does not exist, please register first':
      'Dieses Konto existiert nicht, bitte registrieren Sie sich zuerst',
    'Synchronization successful, no new data':
      'Synchronisierung erfolgreich, keine neuen Daten',
    'Location alert': 'Standort-Warnung',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'Das von Ihnen überwachte xxx-Gerät hat am xxxx-xx-xx den Standort xx betreten',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'Das von Ihnen überwachte xxx-Gerät hat am xxxx-xx-xx den Ort xx verlassen.',
    'View more location records:': 'Weitere Standortdatensätze anzeigen:',
    'Thank you!': 'Vielen Dank!',
    'xxx Team': 'xxx Mannschaft',
    'When obtaining this location':
      'Bei der Ermittlung dieses Standorts aktivierte das Gerät die virtuelle Positionierung, was zu Ungenauigkeiten führen kann.',
    'The demo mode does not support this feature, please bind the device first.':
      'Der Demomodus unterstützt diese Funktion nicht. Bitte binden Sie das Gerät zuerst.',
    'Location Tracking': 'Standortverfolgung',
    'General Application': 'Allgemeine Anwendung',
    'App Activites Tracking': 'App-Aktivitätenverfolgung',
    'Screen Time Tracking': 'Bildschirmzeitverfolgung',
    'Social Application': 'Soziale Anwendung',
    'Subject:': 'Thema:',
    '': '',
  },
  famikeep210: {
    'iCloud used storage': 'iCloud genutzter Speicher',
    Online: 'Online',
    Offline: 'Offline',
    'Last known location': 'Letzter bekannter Standort',
    'Please bind your device': 'Bitte binden Sie Ihr Gerät',
    'Log in iCloud to binding.':
      'Melden Sie sich mit dem iCloud-Konto des Zielgeräts an, um die Bindung abzuschließen.',
    'Please enter your Apple ID': 'Bitte geben Sie Ihre Apple ID ein',
    'Please enter your password': 'Bitte geben Sie Ihr Passwort ein',
    'Your entered account or password is incorrect.':
      'Ihr eingegebener Benutzername oder Ihr Passwort ist falsch.',
    'Ensure Apple ID and password':
      'Stellen Sie sicher, dass Sie die Apple ID und das Passwort des Zielgeräts eingeben, sich authentifizieren und die Bindung abschließen.',
    'Forgot Apple ID or password?':
      'Haben Sie Apple ID oder Passwort vergessen?',
    'Devices using the same iCloud account will be bound':
      'Geräte, die denselben iCloud-Account verwenden, werden gebunden.',
    'Two-Factor Authentication': 'Zwei-Faktor-Authentifizierung',
    'A message has been sent':
      'Eine Nachricht mit einem Verifizierungscode wurde an Ihr Gerät gesendet. Bitte geben Sie den Verifizierungscode ein, um fortzufahren.',
    'A message containing a verification code has been sent to xxx.':
      'Eine Nachricht mit einem Verifizierungscode wurde an xxx gesendet. Bitte geben Sie den Verifizierungscode ein, um fortzufahren.',
    'Incorrect verification code': 'Falscher Bestätigungscode',
    'Resend Verification Code': 'Bestätigungscode erneut senden',
    'Get SMS Verification Code': 'SMS-Verifizierungscode erhalten',
    'Please select a phone number to receive the verification code.':
      'Bitte wählen Sie eine Telefonnummer, um den Bestätigungscode zu erhalten.',
    'Please select': 'Bitte auswählen',
    'The current iCloud has expired':
      'Die aktuelle iCloud-Kontoüberprüfung ist abgelaufen. Bitte melden Sie sich erneut in Ihrem iCloud-Konto an, um die Daten zu synchronisieren.',
    'Go to verification >>': 'Zur Überprüfung gehen >>',
    'Data synchronization failed, iCloud account expired':
      'Daten­synchronisation fehlgeschlagen, iCloud-Konto abgelaufen, bitte zurück zur Startseite und erneut überprüfen.',
    'Created geofences': 'Erstellte Geofences',
    'No geofences created': 'Keine Geofences erstellt',
    'FamiKeep - Parental Control App Pricing & Plans':
      'FamiKeep - Elternkontroll-App Preisgestaltung & Pläne',
    '30-Day Money Back Guarantee': '30-Tage Geld-zurück-Garantie',
    'Professional Customer Support': 'Professioneller Kundensupport',
    'All Prices Exclusive of VAT': 'Alle Preise exklusive MwSt.',
    '100% Secure': '100% sicher',
    'More Features to Keep Your Kids Safe Online':
      'Mehr Funktionen, um Ihre Kinder online sicher zu halten',
    Features: 'Funktionen',
    'Monitor App': 'Überwachung',
    'Type of Data': 'Art der Daten',
    'WhatsApp Chat': 'WhatsApp Chat',
    'App Activity': 'App-Aktivität',
    'App Blocker': 'App-Sperre',
    'Monitor Social Apps': 'Überwachen von sozialen Apps',
    'Location History': 'Standortverlauf',
    'Location Reminder': 'Standorterinnerung',
    'Live Location': 'Live-Standort',
    'Safe Search': 'Sichere Suche',
    'Web Filter': 'Webfilter',
    'Browser History': 'Browserverlauf',
    'Content Detection': 'Inhalterkennung',
    'All SMS': 'Alle SMS',
    Photos: 'Fotos',
    Video: 'Videos',
    Calendar: 'Kalender',
    Documents: 'Dokumente',
    'Record Screen': 'Bildschirm',
    'Call History': 'Anrufverlauf',
    'Call Recording': 'Anrufaufnahme',
    Contacts: 'Kontakte',
    Messages: 'Nachrichten',
    'Call Logs': 'Anruflisten',
    'Chat History': 'Chatverlauf',
    'The device has been bound to other account':
      'Das Gerät wurde an ein anderes Konto gebunden. Bitte heben Sie die Bindung zuerst auf oder wenden Sie sich an den Kundendienst, um die Bindung aufzuheben.',
    Gallery: 'Galerie',
    'Hidden or Deleted Albums': 'Versteckte oder gelöschte Alben',
    'Recent Projects': 'Letzte Projekte',
    'Personal Collection': 'Persönlich',
    'Recently Deleted': 'Zuletzt gelöscht',
    'Live Photos': 'Live-Foto',
    'Exposure Photos': 'Belichtungsfoto',
    'Panoramic Photos': 'Panoramafoto',
    'Time-Lapse Photography': 'Zeitrafferfotografie',
    'Slow Motion': 'Zeitlupe',
    'Depth Photos': 'Tiefenfoto',
    'Burst Mode': 'Serienaufnahme',
    Screenshot: 'Bildschirmfoto',
    Motion: 'Action',
    Hide: 'Verstecken',
  },
  // 用户模块
  language: 'Sprache',
  signIn: 'Anmelden',
  email: 'E-Mail',
  password: 'Passwort',
  sendCode: 'Code senden',

  // 登录
  signingIn: 'Anmeldung',
  noAccount: 'Kein Konto?',
  signUpNow: ' Jetzt anmelden.',
  forgetPassword: 'Haben Sie Ihr Passwort vergessen?',

  // 注册
  signUp: 'Anmelden',
  createAccount: 'Konto erstellen',
  emVerificationCode: 'Verifizierungscode',
  creatingAccount: 'Konto erstellen…',
  alreadyHaveAccount: 'Sie haben bereits ein Konto?',
  SignInUp: 'Anmelden',
  sing: 's',

  // 忘记密码
  retrievePassword: 'Passwort wiederherstellen',
  backTo: 'Zurück zu Anmelden',
  newPassword: 'Neues Passwort',
  resetPassword: 'Passwort zurücksetzen',
  loginSucceeded: 'Anmelden erfolgreich!',
  logoutSucceeded: 'Anmelden erfolgreich!',

  // 用户模块异常提示
  passwordMmustBe8:
    'Das Passwort muss aus 8-20 Zeichen bestehen und eine Kombination aus Buchstaben und Zahlen sein.',
  pleaseEnterPassword: 'Bitte geben Sie ihr Kontopasswort ein',
  pleaseEnterEmailAddress: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
  invalidEmailFormat: 'Ungültiges E-Mail-Format',
  pleaseEnterVerifiCode: 'Bitte geben Sie den Bestätigungscode ein',
  incorrectVerifiCode: 'Ungültiger Verifizierungscode',

  // Toast文案
  verificationCodeHasBeen:
    'Der Verifizierungscode wurde gesendet, bitte überprüfen Sie ihre E-Mail',
  pleaseReadAndAgree:
    'Bitte lesen und akzeptieren Sie die Nutzungsbedingungen und die Datenschutzrichtlinie',
  networkAbnormal: 'Netzwerk fehlerhaft',
  emailRegistered:
    'Die E-Mail ist noch nicht registriert, bitte registrieren Sie sich zuerst',
  passwordNotold: 'Ihr neues Passwort kann nicht dasselbe sein wie das alte',
  invalidPasswordFormat: 'Ungültiges Passwort-Format',
  theEmailHasRegistered: 'Die E-Mail wurde registriert',
  verificationCodeGetAgain:
    'Der Verifizierungscode ist abgelaufen, bitte fordern Sie ihn erneut an',
  erificationCodeMatch: 'E-Mail und Verifizierungscode stimmen nicht überein',
  incorrectPasswordOrEmail:
    'Falsches Passwort oder E-Mail, bitte geben Sie es erneut ein',
  requestError: 'Fehler bei der Anfrage',
  pleaseTryAgainLater:
    'Zu viele Anfragen, bitte versuchen Sie es später noch einmal',
  accessDenied: 'Zugang verweigert',
  requestErrorResourceNotFound:
    'Fehler bei der Anfrage, die Ressource wurde nicht gefunden.',
  serverError: 'Server-Fehler',
  connectionServerFailed: 'Verbindung zum Server fehlgeschlagen',
  requestTimedOut: 'Zeitüberschreitung der Anfrage',
  networkAbnormaled: 'Netzwerkfehler',
  serviceUnavailable: 'Dienst nicht verfügbar',
  httpVersioSupportTheRequest: 'Die http-Version unterstützt die Anfrage nicht',
  wrongPasswordTime:
    'Sie haben 5 Mal das falsche Passwort eingegeben. Ihr Konto ist vorübergehend gesperrt und kann nicht eingeloggt werden. Bitte versuchen Sie es in 10 Minuten erneut.',
};
