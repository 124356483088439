import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!
  const _component_overloading = _resolveComponent("overloading")!
  const _component_noticeDialog = _resolveComponent("noticeDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_config_provider, { locale: _ctx.locale }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view, { class: "router" })
      ]),
      _: 1
    }, 8, ["locale"]),
    (_ctx.store.state.overLoading)
      ? (_openBlock(), _createBlock(_component_overloading, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showExpired)
      ? (_openBlock(), _createBlock(_component_noticeDialog, {
          key: 1,
          visible: _ctx.showExpired,
          title: _ctx.$t('pop.remind'),
          width: 480,
          info: `${_ctx.$t('dashboard.expiredOne')} ${_ctx.expiredDate}${_ctx.$t(
    'dashboard.beExpTwo'
  )}${_ctx.$t('dashboard.expiredTwo')}`,
          onConfirm: _ctx.goPay,
          onCancle: _ctx.closeExpired,
          Okay: _ctx.$t('pop.expiredPay')
        }, null, 8, ["visible", "title", "info", "onConfirm", "onCancle", "Okay"]))
      : _createCommentVNode("", true)
  ], 64))
}