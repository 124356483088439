const mutations: any = {
  setAccessToken(state: any, data: any) {
    console.log('datadatadatadatadata:', data);

    state.accessToken = data;
  },
  SET_MYROUTE(state: any, routes: Array<any>) {
    state.myPermission = routes;
  },
  //当前选中的导航
  SET_CURRENT_MENU(state: any, currentMenu: string) {
    state.currentMenu = currentMenu;
  },
  //导航栏是否折叠
  toggleNavCollapse(state: any) {
    state.isSidebarNavCollapse = !state.isSidebarNavCollapse;
  },
  /* 面包屑导航列表 */
  setCrumbList(state: any, list: Array<any>) {
    state.crumbList = list;
  },
  setUpgradeDialog(state: any, status: boolean) {
    state.isShowUpgradeDialog = status;
  },
  setShowPaidDialog(state: any, status: boolean) {
    state.isShowPaidDialog = status;
  },
  // 登录状态
  setLoginStatus(state: { loginStatus: any }, status: any) {
    state.loginStatus = status;
  },
  // 修改当前激活项
  setCurrentDevice(state: any, data: any) {
    state.currentDevice = data;
  },
  // 为更新保留获取的最新的设备
  setCurrentDeviceId(state: any, data: any) {
    // console.log("id:::", data);
    state.currentDeviceId = data;
  },
  // 保留苹果id
  setCurrentAppleId(state: any, data: any) {
    // console.log("id:::", data);
    state.currentAppleId = data;
  },
  // 保存当前设备是安卓还是ios
  setDeviceType(state: any, data: any) {
    console.log('setDeviceTypedata:', data);
    state.deviceType = data;
  },
  setCurrentDeviceInfo(state: any, data: any) {
    console.log('currentDeviceInfo', data);
    state.currentDeviceInfo = data;
  },
  setDefaultMenu(state: any, data: any) {
    state.defaultMenu = data;
  },
  // 控制展示当前设备栏的展示与否
  setSingleDeviceShow(state: any, data: any) {
    state.singleDeviceShow = data;
  },
  // 添加设备
  getDeviceInfo(state: any, data: any) {
    state.deviceInfo = data;
  },
  // 保存过期设备列表
  setExpiredDevices(state: any, data: any) {
    state.expiredDevices = data;
  },
  // 保存当前绑定设备
  setBoundedDevice(state: any, data: any) {
    // console.log("setBoundedDevice", data);
    state.boundedDevice = data;
  },
  // 获取用户信息
  getUserInfo(state: any, data: any) {
    state.userInfo = data;
  },
  // 保存权限信息
  setSubscripts(state: any, data: any) {
    state.subscripts = data;
  },
  // 登录类型
  setModalStatus(state: any, status: any) {
    state.modalStatus = status;
  },
  // 登录验证
  setIsLoginExpired(state: { isLoginExpired: any }, status: any) {
    state.isLoginExpired = status;
  },
  // 设置聊天模块左侧tab栏当前激活的tabItem
  setActiveTabName(state: any, status: any) {
    // console.log("=====setActiveTabName", status);
    state.activeTabName = status;
    // console.log(state.activeTabName);
  },
  // 设置左侧tab栏chat列表中当前被点击的列表项的key值
  setClickItemKey(state: any, status: any) {
    state.clickItemKey = status;
    // console.log(state.clickItemKey);
  },
  setSessionClickItem(state: any, status: any) {
    state.sessionClickItem = status;
  },
  // 设置左侧tab栏mail列表中当前被点击的item数据
  setClickMailItemDetail(state: any, status: any) {
    state.clickMailItemDetail = status;
    // console.log(state.clickMailItemDetail);
  },
  // 设置左侧tab栏phone列表中当前被点击的item数据
  setClickPhoneItemDetail(state: any, status: any) {
    state.clickPhoneItemDetail = status;
    // console.log(state.clickPhoneItemDetail);
  },

  setMenuList2(state: any, status: any) {
    state.menuList2 = status;
  },
  setToken(state: any, status: any) {
    state.token = status;
  },
  setDatePickerValue(state: any, status: any) {
    state.datePickerValue = status;
  },
  setIsDemo(state: any, status: any) {
    state.isDemo = status;
  },
  setSource(state: any, status: any) {
    state.source = status;
  },
  setHashValue(state: any, status: any) {
    state.hashValue = status;
  },
  setCurrentLang(state: any, status: any) {
    state.currentLang = status;
  },
  setDownloadNumber(state: any, status: any) {
    state.downloadNumber = status;
  },
  setPayFrom(state: any, status: any) {
    state.payFrom = status;
  },
  setPerStatus(state: any, status: any) {
    state.perStatus = status;
  },
  setPayMenu(state: any, status: any) {
    state.payMenu = status;
  },
  setBoundImg(state: any, status: any) {
    state.boundImg = status;
  },
  // setIsClick(state: any, status: any) {
  //   state.isClick = status;
  // },
  setClickGoPay(state: any, status: any) {
    state.clickGoPay = status;
  },
  setPhoneSource(state: any, status: any) {
    state.phoneSource = status;
  },
  setClenderSource(state: any, status: any) {
    state.calenderSource = status;
  },
  setCallLogsSource(state: any, status: any) {
    state.callLogsSource = status;
  },
  setAppListSource(state: any, status: any) {
    state.appListSource = status;
  },
  setExpired(state: any, status: any) {
    state.expired = status;
  },
  setTargetDeviceLength(state: any, status: any) {
    state.targetDeviceLength = status;
  },
  setWsObj(state: any, status: any) {
    // console.log("status", status);
    state.wsObj = status;
  },
  setWhatsappFrom(state: any, status: any) {
    state.whatsappFrom = status;
  },
  setMessageToastType(state: any, status: any) {
    // console.log("setMessageToastType", status);
    state.messageToastType = status;
  },
  setIsClickUpdateBtn(state: any, status: any) {
    state.isClickUpdateBtn = status;
  },
  setIsExpired(state: any, status: any) {
    state.isExpired = status;
  },
  setBeExpired(state: any, status: any) {
    state.beExpired = status;
  },
  setClickedArray(state: any, status: any) {
    state.clickedArray = status;
  },
  setAutoRenew(state: any, status: any) {
    state.autoRenew = status;
  },
  setDeviceInfoSource(state: any, status: any) {
    state.deviceInfoSource = status;
  },
  setCurrentMonitorApp(state: any, status: any) {
    state.currentMonitorApp = status;
  },
  setAppFrom(state: any, status: any) {
    state.appFrom = status;
  },
  setChatListSource(state: any, status: any) {
    state.chatListSource = status;
  },
  setPhotoInfoSource(state: any, status: any) {
    state.photoInfoSource = status;
  },
  setIsShowBack(state: any, status: any) {
    state.isShowBack = status;
  },
  setIsExpiredLogin(state: any, status: any) {
    state.isExpiredLogin = status;
  },
};
export default mutations;
