
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  watch,
  toRefs,
  ref,
} from "vue";
// 实现右侧栏悬浮窗
// import SET from "@/components/set/set.vue";
// 全局loading
import { ElConfigProvider } from 'element-plus'
import overloading from "@/components/overloading/overloading.vue";
import { useStore } from "vuex";
import { number } from "echarts";
import { loginDemo } from "@/api/login";
// import { updateMenuList } from '@/components/nav/component/config/deviceInfo'
import { useRoute, useRouter } from "vue-router";
import { getQueryParam } from "@/utils/format";
import { progressbarWs } from "@/api/login";
import emitter from "@/utils/eventBus";
import { dateFormat, getBrowserNameVersion } from "@/utils/format";
import noticeDialog from "@/components/homePage/tabItem/noticeDialog.vue";
import moment from "moment";
import { gaEvent } from "@/utils/format";
import { event } from "vue-gtag";
import {
  useCheckNotification,
  checkIs24HVisit,
} from "@/components/common/hooks";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { config } from "@/utils/config";
import tools from "@/utils/tools";
import { changeCountry } from "@/api/devices";
// import Cookies from 'js-cookie'

import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import de from 'element-plus/lib/locale/lang/de'
import es from 'element-plus/lib/locale/lang/es'
import fr from 'element-plus/lib/locale/lang/fr'
import ja from 'element-plus/lib/locale/lang/ja'
import ru from 'element-plus/lib/locale/lang/ru'
import ptBr from 'element-plus/lib/locale/lang/pt-br'
import ar from 'element-plus/lib/locale/lang/ar'
import it from 'element-plus/lib/locale/lang/it'
import ko from 'element-plus/lib/locale/lang/ko'
import nl from 'element-plus/lib/locale/lang/nl'
import tr from 'element-plus/lib/locale/lang/tr'
import id from 'element-plus/lib/locale/lang/id'
import th from 'element-plus/lib/locale/lang/th'
import zhTw from 'element-plus/lib/locale/lang/zh-tw'




export default defineComponent({
  setup(props) {
    let router = useRouter();
    let route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const isVibe = computed(() => store.state.isVibe);
    // console.log("isVibe:", isVibe.value);
    if (!isVibe.value && !localStorage.getItem("authorization")) {
      // console.log("----");
      loginDemo().then((res) => {
        if (res.data.code == "200") {
          // console.log(res.data, "res.data");
          let data = new Date().getTime();
          // store.state.loginTime = timeRes - store.state.loginTime;
          // router.push({ path: "/home" });
          localStorage.setItem("token", res.data.data.token);
          // test用
          localStorage.setItem("email", res.data.data.email);
          // 登录密钥
          localStorage.setItem("authorization", res.data.data.token);
          console.log("res.data.authorization:", res.data.data.token);

          // Cookies.set('authorization', res.data.data.token, { expires: 30 });
          store.commit('SET_Authorization', res.data.data.token);
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          localStorage.setItem("user_id", res.data.data.user_id);
          // 新接口返回的数据没有userInfo字段
          // 但是登录的时候有需要拿到数据，之前的将字段的id以及email都封装在userInfo中
          // 所以这里要都拿到数据就直接将所有数据都传递过去，因为反正是同层级的，之后要用哪个就直接取就好了
          tools.UserEvent("Login_Success", "None");
          tools.setCookie("sssLLL", 1, 1);

          store
            .dispatch("getDeviceInfo")
            .then(async () => {
              if (store.state.deviceInfo && store.state.deviceInfo.length > 0) {
                console.log('Appvue');
                if (store.state.deviceInfo[0].id) {
                  store.commit(
                    "setCurrentDeviceId",
                    store.state.deviceInfo[0].id
                  );
                  store.commit('setDeviceType', 0);
                } else {
                  store.commit('setCurrentAppleId', store.state.deviceInfo[0].apple_id)
                  store.commit(
                    "setCurrentDeviceId",
                    store.state.deviceInfo[0].devices[0].id
                  );
                  store.commit('setDeviceType', 1);
                }
              }

              await store.dispatch("getUserInfo").catch((err) => {
                router.push("/login");
                throw new Error("error~~~");
              });
              if (!store.state.subscripts.expires_at) {
                store.commit("setDefaultMenu", null);
                router.push({ path: "/addDevice" });
              } else if (
                !store.state.deviceInfo.length &&
                store.state.subscripts.expires_at
              ) {
                if (
                  store.state.subscripts.expires_at &&
                  data / 1000 > store.state.subscripts.expires_at
                ) {
                  store.commit("setExpired", true);
                  store.commit("setDefaultMenu", null);
                  router.push({ path: "/addDevice" });
                } else {
                  store.commit("setDefaultMenu", null);
                  router.push({ path: "/guide" });
                  store.commit("setCurrentDeviceId", "Device 1");
                  store.commit("setCurrentDeviceInfo", {
                    name: "leftTab.unboundName",
                    id: "Device 1",
                    model: "leftTab.unbound",
                  });
                }
                store.commit("setBoundImg", true);
              } else {
                router.push({ path: "/home" });
                store.commit("setDefaultMenu", "home");
                if (
                  store.state.subscripts.expires_at &&
                  data / 1000 > store.state.subscripts.expires_at
                ) {
                  store.commit("setExpired", true);
                }
              }

              // 这里的斜杠会被转化成转义运算符
              let total =
                store.state.deviceInfo.length +
                store.state.subscripts.total_effective_pit;

              if (!store.state.subscripts.total_effective_pit) {
                const res11 = store.state.deviceInfo.length + "/" + "0";
                event("Binddevice_LoginSuccess", {
                  event_category: "AddDevice",
                  event_label: res11,
                });
              } else {
                const res11 = store.state.deviceInfo.length + "/" + total;
                event("Binddevice_LoginSuccess", {
                  event_category: "AddDevice",
                  event_label: res11,
                });
              }
              // // 调取设备信息
              store.dispatch("updateMenuList");
              // ElMessage.success(i18n.t("loginSucceeded"));
            })
            .catch((err) => {
              router.push("/login");
              throw new Error("error~~~");
            });
        }
        return;
      });
    }
    let theme = window.localStorage.getItem("theme");
    theme && window.document.documentElement.setAttribute("data-ming", theme);

    const isToday1 = (str: any) => {
      return (
        // 判断传入的时间是否是当天
        new Date(JSON.parse(str)).toDateString() === new Date().toDateString()
      );
    };

    // 权限过期
    const showExpired = ref(false);
    // 控制权限过期dialog消失
    const closeExpired = () => {
      showExpired.value = false;
      gaEvent("Expired_PopClose", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
    };
    // 过期弹窗显示的过期时间
    const expiredDate = computed(() => store.state.isExpired);

    const goPay = () => {
      gaEvent("Expired_PopRenew", {
        event_category: "Dashboard_Function",
        event_label: "None",
      });
      gaEvent("Renew", {
        event_category: "Dashboard_Function",
        event_label: "From_Popups",
      });
      store.commit("setPayFrom", 2);
      showExpired.value = false;
      store.commit("setDefaultMenu", null);
      router.push({ path: "addDevice" });
      gaEvent("Buy_Page", {
        event_category: "Buy",
        event_label: "From_Renew",
      });
    };
    // 已绑定设备的点击
    const expiredDevices = computed(() => store.state.expiredDevices);
    // clickedArray用于保存过期设备是否点击过
    const clickedArray = computed(() => store.state.clickedArray);
    // 该数组取出所有的id，用于判断当前点击的id是否在已点击的数组里面
    const ids: any = ref([]);
    // 用于判断该设备是否是过期设备
    const expIds: any = ref([]);
    clickedArray.value.forEach((item: any) => {
      ids.value.push(item.id);
    });
    // expiredDevices.value.forEach((item: any) => {
    //   expIds.value.push(item.id)
    // })
    watch(expiredDevices, () => {
      // console.log("过期设备列表发生变化");
      expiredDevices.value.forEach((item: any) => {
        expIds.value.push(item.id);
      });
    });

    const deviceId = computed(() => store.state.currentDeviceId);
    const currentAppleId = computed(() => store.state.currentAppleId);
    const modalStatusCode = computed(() => store.state.modalStatus);
    const deveiceInfo = computed(() => store.state.deviceInfo);
    const deviceType = computed(() => store.state.deviceType);
    const curDeviceExpired = computed(
      () => store.state.currentDeviceInfo?.expire_at
    );
    const curDevIsExpired = computed(
      () => store.state.currentDeviceInfo?.is_expired
    );
    const [messageToastType, checkNotification] = useCheckNotification();

    /*     watch([modalStatusCode], () => {
      // 设备切换时，重置聊天模块tab栏选中项
      store.commit("setActiveTabName", "first");
      pathChange();
    }); */

    watch(
      () => route.path,
      (newPath: any, oldPath: any) => {
        // console.log("route改变", newPath);

        pathChange(newPath);
      }
    );
    watch([deviceId], () => {
      // 设备切换时，重置聊天模块tab栏选中项
      store.commit("setActiveTabName", "first");
      getExpiredTime();
      if (expiredDevices.value.some((item: any) => item.id == deviceId.value)) {
        setClickedArray();
      }
    });
    const currentLang = computed(() => store.state.currentLang);
    const userId = computed(() => store.state.userInfo.id);
    onMounted(() => {
      // console.log(`版本号: ${config.version}`);
      console.log(
        '%c周某鹏荣誉出品-> ' + config.version,
        'font-size:44px;color:violet;font-style:italic;border:4px solid violet;'
      );
      console.log("系统语言：", store.state);
      let tempValue = currentLang.value
      if (tempValue == 'ja') tempValue = 'jp'
      else if (tempValue == 'ms') tempValue = 'my'
      else if (tempValue == 'ko') tempValue = 'kr'
      else if (tempValue == 'pt') tempValue = 'br'
      else if (tempValue == 'zh-CN') tempValue = 'cn'
      else if (tempValue == 'zh-TW') tempValue = 'tw'
      let params = {
        language: tempValue
      }
      if (window.location.href.includes('login')) {

      } else {
        changeCountry(params, userId.value).then((res) => {
          console.log("成功修改语言了老弟");
        }).catch((e) => {
          console.log("老弟修改不了语言啊:", e);
        })
      }
      // pathChange();
      // ==== 总网站访问量UV 埋点 start ====
      const visitTime = {
        year: moment().get("year"),
        month: moment().get("month") + 1,
        day: moment().get("date"),
        timestamp: moment().format("X"),
      };

      const end = moment().endOf("day").format("X"); // 当天的起始时间戳（单位：s）
      const start = moment().startOf("day").format("X"); // // 当天的结束时间戳（单位：s）
      const localVisitTime = JSON.parse(
        localStorage.getItem("lastVisitTime") as string
      );

      if (!localVisitTime) {
        // 新用户第1次访问
        // console.log('新用户, 直接统计1次访问量');
        gaEvent("Web_View", {
          event_category: "WebView",
          event_label: "New_User",
        });
      } else {
        // 老用户访问
        // console.log('老用户访问');

        if (
          !(
            localVisitTime.timestamp >= start && localVisitTime.timestamp <= end
          )
        ) {
          // 同一天访问网站时，只统计1次访问量
          // console.log('老用户当天的第一次访问，统计1次访问量');
          gaEvent("Web_View", {
            event_category: "WebView",
            event_label: "Old_User",
          });
        }
      }
      localStorage.setItem("lastVisitTime", JSON.stringify(visitTime));
      // ==== 总网站访问量UV 埋点 end ====

      const browserVersion = getBrowserNameVersion();

      gaEvent("WebVisit_Browser", {
        event_category: "WebView",
        event_label: browserVersion,
      });

      // 每次跳转路由前，判断当前url是否存在?isDemo=1后缀，有的话进行初始化demo数据
      const isDemo = getQueryParam(location.href, "isDemo");

      store.commit("setIsDemo", parseInt(isDemo));
      // 为了实现移动端和PC端切换时可以携带后缀进入到相同的页面
      // store.commit("setHashValue", location.hash.slice(1));
      store.commit("setHashValue", location.hash);
      const hash = computed(() => store.state.hashValue);

      let storage = window.localStorage;
      let isToday = storage.getItem("isToday") || null;
      if (isToday == null || !isToday1(isToday)) {
        //   // 判断用户如果是首次进入页面，则设置初始路由
      }

      let data = new Date().getTime();

      storage.setItem("isToday", String(data));
      if (localStorage.getItem("token")) {
        // 从demo模式切换到正常模式时，要重新获取用户信息来替换掉vuex中的数据

        // if (!isDemo) {
        (async () => {
          await store.dispatch("getUserInfo");
          await store.dispatch("getDeviceInfo");
          if (deveiceInfo.value.length > 0) {
            console.log('App fix');
            const fix = ref(false);

            if (deviceType.value) {
              deveiceInfo.value.forEach((item: any, index: any) => {
                if (item.apple_id == currentAppleId) {
                  deveiceInfo.value[index].devices.forEach(
                    (cItem: any, cIndex: any) => {
                      if (cItem.id == deviceId.value) {
                        fix.value = true;
                      }
                    }
                  );
                }
              });
            } else {
              for (let i of deveiceInfo.value) {
                if (deviceId.value == i.id) {
                  fix.value = true;
                }
              }
            }

            if (!fix.value) {
              if (deveiceInfo.value[0].id) {
                console.log('----ififif');
                store.commit("setCurrentDeviceId", deveiceInfo.value[0].id);
                store.commit("setCurrentDeviceInfo", deveiceInfo.value[0]);
                store.commit('setDeviceType', 0);
              } else {
                console.log('elseelse');
                store.commit("setCurrentDeviceId", deveiceInfo.value[0].devices[0].id);
                store.commit("setCurrentDeviceInfo", { ...deveiceInfo.value[0].devices[0], is_expired: deveiceInfo.value[0].is_expired, expire_at: deveiceInfo.value[0].expired_at });
                store.commit('setDeviceType', 1);
              }
            }

            router.push("home");
          }
          // 为了在处于whatsapp页面的时候，重新发送获取当前设备信息的接口
          if (window.location.href.includes("whatsapp")) {
            console.log('whatsapp');
            await store.dispatch("getCurrentDeviceInfo");
          }
          store.dispatch("updateMenuList");
        })();
        getExpiredTime();
        setClickedArray();
      }

      // 用户没权限
      if (!toRefs(store.state.subscripts).expires_at.value) {
        store.commit("setPerStatus", "User_NotBuy");
      } else {
        let time = computed(() => {
          return store.state.subscripts.expires_at;
        });
        // 有权限
        if (time.value > data / 1000) {
          store.commit("setPerStatus", "User_Buy_Permission");
        } else {
          // 权限已过期
          store.commit("setPerStatus", "User_Buy_NoPermission");
        }
      }
    });
    // if (localStorage.getItem('token')) {
    //   setInterval(() => {
    //     store.dispatch('getDeviceInfo')
    //     updateMenuList()
    //   }, 60000)
    // }
    let pathChange = (path: any) => {
      if (path == "/login") {
        gaEvent("LoginPage_View", {
          event_category: "LogRegister",
          event_label: "None",
        });
      } else if (path == "/signup") {
        gaEvent("RegisterPage_View", {
          event_category: "LogRegister",
          event_label: "None",
        });
      } else if (path == "/forgotpassword") {
        gaEvent("FindPassword_View", {
          event_category: "LogRegister",
          event_label: "None",
        });
      }
    };

    const deviceList = computed(() => store.state.deviceInfo);
    const refreshTab = async () => {
      await store.dispatch("getUserInfo");
      await store.dispatch("getDeviceInfo");
      if (store.state.deviceInfo.length > 0) {
        router.push({ path: "/home" });
        store.commit("setBoundImg", false);
        console.log('App refreshTab');
        if (store.state.deviceInfo[0].id) {
          store.commit("setCurrentDeviceId", store.state.deviceInfo[0].id);
          store.commit("setCurrentDeviceInfo", store.state.deviceInfo[0]);
          store.commit('setDeviceType', 0);
        } else {
          store.commit("setCurrentDeviceId", store.state.deviceInfo[0].devices[0].id);
          store.commit("setCurrentDeviceInfo", {
            ...store.state.deviceInfo[0].devices[0], is_expired: store.state.deviceInfo[0].is_expired, expire_at: store.state.deviceInfo[0].expired_at
          });
          store.commit('setDeviceType', 1);
        }
        let arr;
        let boundedDevice = ref();
        arr = deviceList.value.map((item: any) => {
          // 有apple_id为苹果设备
          if (!item.apple_id) {
            if (item.model) {
              return item.model;
            } else {
              return item.name;
            }
          } else {
            return item.apple_id
          }
        });
        boundedDevice.value = arr.join("、");
        store.commit("setBoundedDevice", boundedDevice.value);
      } else {
        if (store.state.deviceType) {
          router.push({ path: "/iosLogin" });
        } else {
          router.push({ path: "/guide" });
        }
        store.commit("setDefaultMenu", null);
        router.push({ path: "/guide" });
        store.commit("setCurrentDeviceId", "Device 1");
        store.commit("setCurrentDeviceInfo", {
          name: "leftTab.unboundName",
          id: "Device 1",
          model: "leftTab.unbound",
        });
        store.commit("setBoundImg", true);
      }
      store.dispatch("updateMenuList", deviceList.value);
    };

    // 用于用户解绑之后，首页消息的变化
    watch(curDevIsExpired, () => {
      // console.log('aaaaaaa')
      getExpiredTime();
    });

    // 刷新来获取最新到期时间
    const getExpiredTime = () => {
      // 封装
      const now: number = parseInt((new Date().getTime() / 1000).toFixed(0));
      // 过期时间小数向下取整
      const expiredTime = Math.round((now - curDeviceExpired.value) / 86400);
      // console.log('current', curDevIsExpired.value)

      if (curDevIsExpired.value) {
        // 过期的情况

        // console.log("expiredTime1", expiredTime)
        if (expiredTime >= 0 && expiredTime <= 31) {
          store.commit("setMessageToastType", 3);
          if (30 - expiredTime < 0) {
            store.commit("setIsExpired", 0);
          } else {
            store.commit("setIsExpired", 30 - expiredTime);
          }
        }
      } else {
        // 未过期的情况
        if (Math.abs(expiredTime) <= 7) {
          // console.log('<7')
          store.commit("setMessageToastType", 2);
          store.commit("setBeExpired", Math.abs(expiredTime));
        } else {
          checkNotification(
            () => {
              ElMessage({
                message: t("chat.syncSuccessed"),
                type: "success",
              });
            },
            () => {
              ElMessage({
                message: t("chat.syncFailed"),
                type: "error",
              });
            }
          );
        }
      }
    };

    // 保存过期设备的已点击列表
    const setClickedArray = () => {
      // console.log(
      //   "setClickedArray",
      //   !ids.value.includes(deviceId.value),
      //   expIds.value.includes(deviceId.value)
      // );

      if (
        !ids.value.includes(deviceId.value) &&
        expIds.value.includes(deviceId.value)
      ) {
        // console.log("第一次");
        showExpired.value = true;
        gaEvent("Expired_Pop", {
          event_category: "Dashboard_Function",
          event_label: "None",
        });
      }

      // 判断是否在当天点击的该设备,如果是某一天的第一次,则弹弹窗
      clickedArray.value.forEach((item: any) => {
        if (item.id == deviceId.value && ids.value.includes(deviceId.value)) {
          const end = moment().endOf("day").format("X"); // 当天的起始时间戳（单位：s）
          const start = moment().startOf("day").format("X"); // // 当天的结束时间戳（单位：s）
          // console.log("item.timestamp", item.timestamp, end, start);

          if (!(item.timestamp > start && item.timestamp < end)) {
            // console.log("come");

            if (expiredDevices.value.length > 0) {
              const flag = ref(false);
              for (let i of expiredDevices.value) {
                if (item.id == i.id) {
                  flag.value = true;
                }
              }
              if (!flag.value) {
                // console.log("已换绑的设备");
                return;
              }
            }
            // console.log("第一次点击已过期的设备");
            showExpired.value = true;
            gaEvent("Expired_Pop", {
              event_category: "Dashboard_Function",
              event_label: "None",
            });
          }
        }
      });

      // 保存已点击的过期设备数组，保存id以及点击时间
      for (let i = 0; i < expiredDevices.value.length; i++) {
        if (expiredDevices.value[i].id == deviceId.value) {
          // 已过期设备的点击
          const obj = {
            id: deviceId.value,
            timestamp: Math.floor(new Date().getTime() / 1000),
          };
          const newArray = clickedArray.value;
          for (let j = 0; j < clickedArray.value.length; j++) {
            if (deviceId.value == clickedArray.value[j].id) {
              newArray.splice(j, 1);
            }
          }
          // 在切换id的时候要push，进去，因为刷新就会恢复默认值，并重新根据clickArray重新赋值
          ids.value.push(deviceId.value);
          newArray.push(obj);
          store.commit("setClickedArray", newArray);
        }
      }
    };
    const payFrom = computed(() => store.state.payFrom);
    let code = localStorage.getItem("authorization");
    if (localStorage.getItem("authorization")) {
      progressbarWs(code, 1).then((res) => {
        // console.log('App Res', res)
        store.commit("setWsObj", res);
      });
    }

    emitter.on("refresh", () => {
      refreshTab();
      if (localStorage.getItem("oos") == "1") {
        emitter.emit("closeMenu");
      }
      // router.push({ path: '/home' })
      localStorage.setItem("oos", "3");
    });

    // 1 设备的添加 2 会员的续费或者开通 3 无效果
    if (localStorage.getItem("oos") == "1") {
      // refreshTab()
      // // router.push({ path: '/home' })
      // localStorage.setItem('oos', '3')
    } else if (localStorage.getItem("oos") == "2") {
      // router.push({ path: "/guide" });
      // location.reload();
      gaEvent("Buy_Success_Status", {
        event_category: "Buy",
        event_label: store.state.perStatus,
      });
      if (payFrom.value == 0) {
        gaEvent("Buy_Success_Source", {
          event_category: "Buy",
          event_label: "User_From_PurchaseNewPlan",
        });
      } else if (payFrom.value == 1) {
        gaEvent("Buy_Success_Source", {
          event_category: "Buy",
          event_label: "User_From_Adddevice",
        });
      } else {
        gaEvent("Buy_Success_Source", {
          event_category: "Buy",
          event_label: "User_From_Renew",
        });
      }
      gaEvent("Buy_Success_LicenseChoose", {
        event_category: "Buy",
        event_label: store.state.payMenu,
      });
      refreshTab();

      // 如果付款成功跳转回首页
      // router.push({ path: '/home' })
      localStorage.setItem("oos", "3");
    }

    const locale = ref()
    watch(currentLang, (newV) => {
      console.log('newV', newV);
      if (newV == 'en') {
        locale.value = en
      } else if (newV == 'zh-CN') {
        locale.value = zhCn
      } else if (newV == 'de') {
        locale.value = de
      } else if (newV == 'es') {
        locale.value = es
      } else if (newV == 'fr') {
        locale.value = fr
      } else if (newV == 'ja') {
        locale.value = ja
      } else if (newV == 'ru') {
        locale.value = ru
      } else if (newV == 'pt') {
        locale.value = ptBr
      } else if (newV == 'ar') {
        locale.value = ar
      } else if (newV == 'it') {
        locale.value = it
      } else if (newV == 'ko') {
        locale.value = ko
      } else if (newV == 'nl') {
        locale.value = nl
      } else if (newV == 'tr') {
        locale.value = tr
      } else if (newV == 'id') {
        locale.value = id
      } else if (newV == 'th') {
        locale.value = th
      } else if (newV == 'zh-TW') {
        locale.value = zhTw
      } else {
        locale.value = en
      }
    }, {
      immediate: true
    })

    return {
      // overLoading,
      store,
      showExpired,
      closeExpired,
      goPay,
      expiredDate,
      locale,
    };
  },
  components: {
    // SET,
    overloading,
    progressbarWs,
    noticeDialog,
  },
});
