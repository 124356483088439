import { useStore } from 'vuex';
import { getDeviceApi, getDashBoard, getDeviceInfo } from '@/api/devices';
import { demoDeviceInfo } from '@/components/nav/component/config/deviceInfo';
import { userInfo, userInfoDemo } from '@/api/login';
import { getIcloudInfo } from '@/api/ios';
import store from '@/store/index';
import router from './../router';

export const menuList2 = [
  {
    name: 'Device',
    path: '/device',
    meta: {
      name: 'leftTab.device',
      isDevice: true,
    },
    children: [],
  },
];

const actions = {
  // 获取设备列表
  async getDeviceInfo({ commit, state }: any) {
    // let clickedArray = [];
    try {
      let results = await getDeviceApi({});
      if (results.data.code === 200 && results.data.message === 'OK') {
        console.log('results', results);

        if (
          results.data.data.devices.length == 0 &&
          results.data.data.icloud_accounts.length == 0
        ) {
          // console.log("null");
          commit('setCurrentDevice', 'Device 1');
          commit('getDeviceInfo', []);
        } else {
          let info = results.data.data.devices.concat(
            results.data.data.icloud_accounts
          );

          commit('getDeviceInfo', info);

          const currentTimeStamp = Math.floor(new Date().getTime() / 1000);
          // console.log('currentTimeStamp:', currentTimeStamp);

          console.log('info', info);
          // 处理过期设备列表
          let expDevices = info.filter((item: any) => {
            if (item.id) {
              return item.is_expired;
            } else {
              if (item.expired_at < currentTimeStamp) return true;
            }
          });
          // console.log('expDevices:', expDevices);

          commit('setExpiredDevices', expDevices);
        }
      }
    } catch (err) {
      console.log('err:', err);
    }
  },
  // 获取用户信息
  async getUserInfo({ commit, state }: any) {
    try {
      if (store.state.isDemo) return;

      let results;
      if (!state.isVibe) {
        results = await userInfoDemo({});
      } else {
        results = await userInfo({});
      }

      if (results.data.code === 200 && results.data.message === 'OK') {
        let info = results.data.data.user;
        let autoRenew = results.data.data.is_auto_renew;
        // 保存用户是否开通了自动续费功能
        commit('setAutoRenew', autoRenew);
        let subscripts = results.data.data.subscripts;
        let avatarImg =
          info &&
          info.profile_picture_url &&
          info.profile_picture_url.split('?')[0]
            ? info.profile_picture_url.split('?')[0]
            : null;
        await commit('getUserInfo', {
          id: info.id,
          email: info.email,
          name: info.name,
          profile_picture_url: avatarImg,
        });
        if (subscripts) {
          // let device_num = subscripts.effective_device_num
          //   ? subscripts.effective_device_num
          //   : 0;
          commit('setSubscripts', {
            begins_at: subscripts.begins_at,
            expires_at: subscripts.expires_at,
            total_effective_pit: subscripts.total_effective_pit,
            effective_device_num: subscripts.effective_device_num,
            effective_icloud_num: subscripts.effective_icloud_num,
          });
          let data = new Date().getTime();

          if (data / 1000 > subscripts.expires_at) {
            store.commit('setExpired', true);
          } else {
            store.commit('setExpired', false);
          }
          // commit("setDefaultMenu", "home");
        } else {
          commit('setSubscripts', {});
          commit('setDefaultMenu', null);
        }
      } else {
        throw new Error(results.data.message);
      }
    } catch (err) {
      console.log('err', err);
    }
  },
  // 获取当前设备信息
  // async getCurrentDeviceInfo({ commit, state }: any) {
  async getCurrentDeviceInfo({ commit, state }: any) {
    console.log('android');
    try {
      if (store.state.isDemo) return;
      // 发送请求前拿到上一次的请求取消
      const deviceInfoSourceRef = computed(() => store.state.deviceInfoSource);
      deviceInfoSourceRef.value &&
        (deviceInfoSourceRef.value as any).cancel(
          '请勿频繁请求获取设备详情接口'
        );
      const res = await getDeviceInfo({}, state.currentDeviceId);
      if (res.data.code === 200 && res.data.message === 'OK') {
        let info = res.data.data.device;
        if (!!info.model) {
          commit('setTargetDeviceLength', true);
        }
        commit('setCurrentDevice', info.name);
        commit('setCurrentDeviceId', info.id);
        console.log('安卓获取设备信息');
        commit('setDeviceType', 0);
        commit('setCurrentDeviceInfo', {
          name: info.name,
          model: info.model,
          id: info.id,
          android_version: info.android_version,
          apk_version: info.apk_version,
          battery: info.battery,
          brand: info.brand,
          // 最后更新属性与获取数据时间相关，还待商榷
          last_update_at: info.last_update_at,
          wifi: info.wifi,
          expire_at: info.expire_at,
          is_expired: info.is_expired,
        });
        commit('SET_CURRENT_MENU', info.name);
      }
    } catch (err) {
      console.log(err);
    }
  },

  // 获取当前icloud信息
  async getCurrentIcloudInfo({ commit, state }: any) {
    console.log('ios');
    try {
      console.log('state.currentAppleId', state.currentAppleId);
      const res = await getIcloudInfo(state.currentAppleId);
      if (res.data.code === 200 && res.data.message === 'OK') {
        console.log('res::', res, state.currentAppleId);

        commit('setDeviceType', 1);

        // 找到icloud设备列表下的当前设备
        let currentDevice = {};
        // 用来拿icloud的过期时间
        let currentParent: any = {};
        state.deviceInfo.forEach((item: any, index: any) => {
          if (item.apple_id == state.currentAppleId) {
            console.log('index', state.deviceInfo[index]);
            currentParent = state.deviceInfo[index];
            console.log('currentParent', currentParent);
            state.deviceInfo[index].devices.forEach(
              (cItem: any, cIndex: any) => {
                // console.log(
                //   'cItem.id == state.currentDeviceId',
                //   cItem.id,
                //   state.currentDeviceId
                // );

                if (cItem.id == state.currentDeviceId) {
                  currentDevice = state.deviceInfo[index].devices[cIndex];
                }
              }
            );
          }
        });
        console.log('currentDevice', currentDevice);

        // 将所有apple设备外层的是否过期以及过期时间塞到currentDeviceInfo里面
        commit('setCurrentDeviceInfo', {
          ...currentDevice,
          storage_usage: res.data.data.storage_usage,
          is_expired: currentParent.is_expired,
          expire_at: currentParent.expired_at,
        });
        commit('SET_CURRENT_MENU', res.data.data.apple_id);
        commit('setMessageToastType', 0);
      }
    } catch (err) {
      console.log(err);
    }
  },

  // 更新菜单

  // 当用户为ios用户并且手动切换设备时，菜单要更新
  async updateMenuList(
    { commit, state }: any,
    payload: any = { deviceInfo: state.deviceInfo, getCurrent: false }
  ) {
    const { deviceInfo, getCurrent } = payload;
    console.log('updateMenuList', getCurrent, deviceInfo);

    // 如果用户手动更改icloud的选中设备，就单独改这一项
    if (getCurrent) {
      menuList2[0].children.forEach((item: any, index) => {
        if (item.name == state.currentAppleId) {
          item.meta.id = state.currentDeviceInfo.id;
          item.meta.model = state.currentDeviceInfo.model;
        }
      });
      return;
    }

    // 每次push前都清空数组
    menuList2[0].children = [];

    const deviceConfig = deviceInfo;
    if (deviceConfig.length > 0) {
      for (let i = 0; i < deviceConfig.length; i++) {
        (menuList2[0] as any).children.push(
          !(deviceConfig[i] as any).apple_id
            ? {
                name: (deviceConfig[i] as any).name,
                path: 'home',
                meta: {
                  id: (deviceConfig[i] as any).id,
                  name: (deviceConfig[i] as any).name,
                  icon: require('@/assets/img/Launcher.svg'),
                  isDevice: true,
                  isEmpty: false,
                  isAndorid: true,
                  model: (deviceConfig[i] as any).model,
                },
              }
            : {
                name: (deviceConfig[i] as any).apple_id,
                path: 'home',
                // meta: {
                //   apple_id: (deviceConfig[i] as any).apple_id,
                //   id: getCurrent
                //     ? state.currentDeviceInfo.name
                //     : (deviceConfig[i] as any).devices[0]
                //     ? (deviceConfig[i] as any).devices[0].id
                //     : i,
                //   name: (deviceConfig[i] as any).apple_id,
                //   icon: require('@/assets/img/iphone.svg'),
                //   isDevice: true,
                //   isEmpty: false,
                //   isAndorid: false,
                //   model: getCurrent
                //     ? state.currentDeviceInfo.name
                //     : (deviceConfig[i] as any).devices[0]
                //     ? (deviceConfig[i] as any).devices[0].name
                //     : '--',
                // },
                meta: {
                  apple_id: (deviceConfig[i] as any).apple_id,
                  id: (deviceConfig[i] as any).devices[0]
                    ? (deviceConfig[i] as any).devices[0].id
                    : i,
                  name: (deviceConfig[i] as any).apple_id,
                  icon: require('@/assets/img/iphone.svg'),
                  isDevice: true,
                  isEmpty: false,
                  isAndorid: false,
                  model: (deviceConfig[i] as any).devices[0]
                    ? (deviceConfig[i] as any).devices[0].name
                    : '--',
                },
              }
        );
      }
    }

    // 无权限时的菜单列表，当权限相关的弄好了，就打开此处调试
    // console.log("store.state.subscripts", store.state.subscripts);

    if (!state.subscripts.begins_at) {
      // console.log("comecome");
      commit('setMenuList2', { name: 'whatsapp' });
      return;
    }

    let restAndroid = state.subscripts.effective_device_num;
    let restIos = state.subscripts.effective_icloud_num;
    let length = (menuList2[0] as any).children.length + 1;

    for (let i = length; i < restAndroid + length; i++) {
      (menuList2[0] as any).children.push({
        name: `Device ${i}`,
        path: 'guide',
        meta: {
          id: `Device ${i}`,
          name: 'leftTab.unboundName',
          icon: require('@/assets/img/unLauncher.svg'),
          isEmpty: true,
          isDevice: true,
          isAndorid: true,
          model: 'leftTab.unbound',
        },
      });
    }

    for (let i = length; i < restIos + length; i++) {
      (menuList2[0] as any).children.push({
        name: `Device ${i + restAndroid}`,
        path: 'iosLogin',
        meta: {
          id: `Device ${i + restAndroid}`,
          name: 'leftTab.unboundName',
          icon: require('@/assets/img/grayIphone.svg'),
          isEmpty: true,
          isDevice: true,
          isAndorid: false,
          model: 'leftTab.unbound',
        },
      });
    }
    // console.log("menuList2", menuList2);

    commit('setMenuList2', menuList2);
  },
  // 登录状态
  setLoginStatus({ commit }: any, Status: any) {
    commit('setLoginStatus', Status);
  },
};
export const loadView = (view: String) => {
  // 路由懒加载
  return () => import(`@/views/${view}`);
};
import { computed } from 'vue';

export default actions;
