
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "overLoading",
  setup(props) {
    const store = useStore();
    return {
      store,
    };
  },
});
