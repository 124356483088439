import { gaEvent } from "@/utils/format";
import { ElMessage } from "element-plus";
import i18n from "@/main";


export default {
    LTrim(str: string) {
        var i;
        for (i = 0; i < str.length; i++) {
            if (str.charAt(i) != " ")
                break;
        }
        str = str.substring(i, str.length);
        return str;
    },
    RTrim(str: string) {
        var i;
        for (i = str.length - 1; i >= 0; i--) {
            if (str.charAt(i) != " ")
                break;
        }
        str = str.substring(0, i + 1);
        return str;
    },

    UserEvent(srt: string, data: string) {
        // 用户登录成功时统计一次
        gaEvent(srt, {
            event_category: "LogRegister",
            event_label: data || "None",
        });
    },

    responseUse(data: Number) {
        // 用户登录成功时统计一次
        switch (data) {
            case 400200:
                ElMessage.error(i18n.global.t("invalidPasswordFormat"));
                break;
            case 409101:
                ElMessage.error(i18n.global.t("theEmailHasRegistered"));
                break;
            case 400302:
                ElMessage.error(i18n.global.t("incorrectVerifiCode"));
                break;
            case 400301:
                ElMessage.error(i18n.global.t("verificationCodeGetAgain"));
                break;
            case 400205:
                ElMessage.error(i18n.global.t("erificationCodeMatch"));
                break;
            case 404001:
                ElMessage.error(i18n.global.t("emailRegistered"));
                break;
            case 400201:
                ElMessage.error(i18n.global.t("incorrectPasswordOrEmail"));
                break;
            case 400206:
                ElMessage.error(i18n.global.t("passwordNotold"));
                break;
            case 400103:
                ElMessage.error(i18n.global.t("pleaseTryAgainLater"));
                break;
            case 401534:
                ElMessage.error(i18n.global.t("wrongPasswordTime"));
                break;
            default:
        }
    },

    setCookie(cname: any, cvalue: any, exdays: any) {
        // console.log("进来了");
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toString();
        var Domain = "domain=" + '.famikeep.com';
        document.cookie = cname + "=" + cvalue + expires + '/' + Domain;
    },
    removeItem(sKey: any, sPath: any, sDomain: any) {
        if (!sKey || !this.hasItem(sKey)) { return false; }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
    },
    hasItem(sKey: any) {
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    },
}