export const NL = {
  chat: {
    pictureText: '[Afbeelding]',
    videoText: '[Video]',
    audioText: '[Audio]',
    contactCard: '[Contactkaart]',
    location: '[Plaats]',
    other: '[Ander]',
    all: 'Allemaal',
    today: 'Vandaag',
    yesterday: 'Gisteren',
    last7Days: 'Laatste 7 dagen',
    last30Days: 'Laatste 30 dagen',
    customRange: 'Selecteer aangepast bereik',
    cancel: 'Annuleren',
    apply: 'Van toepassing zijn',
    pushNotify: 'Pushmeldingen',
    numbers: 'Cijfers',
    emails: 'E-mails',
    outgoingCalls: 'Uitgaande gesprekken',
    incomingCalls: 'Inkomende oproepen',
    missedCalls: 'Gemiste oproepen',
    answeredCalls: 'Beantwoorde oproepen',
    noAnswer: 'Geen antwoord',
    canceledCalls: 'Geannuleerde oproepen',
    loadingText: 'Bezig met laden, even geduld...',
    noMoreText: 'Er is geen inhoud meer',
    noInfoText: 'Geen evenementinformatie, controleer het later opnieuw.',
    noAddressText: 'Geen adresboekinformatie, controleer het later opnieuw.',
    noCallText: 'Geen oproeplogboek, controleer het later opnieuw.',
    dateSelect: 'Selecteer een datum',
    sentFile: 'Een bestand verzonden',
    sentAudio: 'Een audio verzonden',
    unGetFile: 'Kan bestand niet ophalen',
    unGetAudio: 'Kan geen audio krijgen',

    syncFailed: 'Gegevens Synchronisatie mislukt!',
    syncSuccessed: 'Gegevens Synchronisatie gelukt!',
    unknownContact: 'Onbekend contact',
    noSyncNotice1: 'Gegevens zijn al meer dan',
    noSyncNotice2: ' 24 uur ',
    noSyncNotice3:
      'niet gesynchroniseerd. Zorg ervoor dat de doeltelefoon is ingeschakeld en verbonden met internet en dat de app "Systeemdienst" normaal werkt.',
    sync: 'Synchroniseren',
    startDate: 'Startdatum',
    endDate: 'Einddatum',
    noSupportToast: 'Datumfiltering wordt niet ondersteund in Demo.',

    unknowVideo:
      'Video. Details zijn niet beschikbaar vanwege het privacybeleid van Instagram.',
    unknowImage:
      'Foto. Details zijn niet beschikbaar vanwege het privacybeleid van Instagram.',
    unknowAudio:
      'Spraakbericht. Details zijn niet beschikbaar vanwege het privacybeleid van Instagram.',
    outgoingVoice: 'Uitgaand videogesprek',
    incomingVoice: 'Inkomend videogesprek',
    outgoingVideo: 'Uitgaande video-oproep',
    incomingVideo: 'Inkomend videogesprek',
  },
  user: {
    userProfile: 'Gebruikersprofiel',
    usesrname: 'Gebruikersnaam:',
    edit: 'Bewerk',
    email: 'E-mail:',
    permission: 'Toestemmingsinformatie',
    bounded: 'Gebonden apparaat(en):',
    restDevice: 'Resterend(e) apparaat(en):',
    expire: 'Vervaldatum:',
    expired: '(Verlopen)',
    logout: 'Uitloggen',
    changename: 'Gebruikersnaam wijzigen',
    save: 'Opslaan',
    inputname: 'Voer een gebruikersnaam in',
    nameNull:
      'Het formaat van de gebruikersnaam is abnormaal, voer het opnieuw in',
    networkError: 'Kan de gebruikersnaam niet wijzigen, controleer uw netwerk',
    nameSuccess: 'Gebruikersnaam succesvol gewijzigd',
    changeavatar: 'Verander avatar',
    loading: 'Bezig met laden…',
    changeAvatar: 'Wijziging',
    supported:
      'Ondersteun jpg-, jpeg-, png-formaten en de grootte mag niet groter zijn dan 2M.',
    maxsize: 'De afbeeldingsgrootte mag niet groter zijn dan 2M',
    avatarError: 'Kan de avatar niet wijzigen, controleer je netwerk',
    avatarSuccess: 'Avatar succesvol gewijzigd',
    imgError: 'Afbeeldingsfout',
    imageType: 'Afbeeldingstype niet ondersteund',
    nameEmpty: 'Gebruikersnaam mag niet leeg zijn',
  },
  leftTab: {
    device: 'Apparaat',
    unbound: 'Niet-gebonden apparaat(en)',
    addDevice: 'Voeg toestel toe',
    dashboard: 'Dashboard',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Bind eerst een apparaat',
    payRemind: 'Gelieve eerst te kopen',
    purchase: 'Nu kopen',
    unboundName: 'Apparaat nu binden',
    toBuy: 'Apparaat kopen',
  },
  guide: {
    title: 'Bind uw apparaat a.u.b.',
    oneT: '1. Doelapparaat ophalen',
    oneContent:
      'Om het apparaat te bewaken, moet u het doelbewakingsapparaat ongeveer 10 minuten hebben.',
    twoT: '2. Downloaden en installeer de app',
    twoContent1: 'Binnenkomen',
    twoContent2:
      'in de browser van het doelapparaat en download en installeer vervolgens de app.',
    threeT: '3. Apparaatrechten instellen',
    threeContent:
      'Volg de instructies in de app om apparaatmachtigingen in te stellen. Na het instellen kunt u het apparaat controleren.',
    tips1:
      "Om stealth te garanderen, wordt de app vermomd als systeemsoftware met de naam 'System Service'.",
    tips2:
      'Om incognito te garanderen, kunt u browserrecords en app-installatiepakketten verwijderen nadat u de app met succes hebt geïnstalleerd',
    tips3:
      "app weergegeven als 'System Service' op het bureaublad. U kunt ervoor kiezen om het in een map te verbergen voor een beter stealth-effect.",
    tips41:
      'Om ervoor te zorgen dat u de app sneller kunt installeren en de machtigingen kunt instellen, kunt u bekijken',
    tips42: 'eerst leren hoe het moet.',
    detail: 'de gedetailleerde zelfstudies',
    tips5:
      'Als de gebonden apparaattoestemming is verlopen, kunt u de apparaatbinding wijzigen en binden aan de toestemming om door te gaan met het verzamelen van gegevens. ',
    rebound: 'Klik om de apparaatbinding te wijzigen',
  },
  dashboard: {
    contact: 'Topcontacten in WhatsApp (binnen 7 dagen)',
    call: 'Populairste oproepen op WhatsApp',
    view: 'Bekijk alles',
    account: 'Account',
    id: 'Account  ID:',
    expire: 'Vervaldatum:',
    bound: 'Gebonden apparaat:',
    restDevice: 'Overige apparaat(en):',
    target: 'Over doelapparaat',
    brand: 'Merk:',
    model: 'Model:',
    version: 'Android-versie:',
    battery: 'Accu:',
    wifi: 'Wifi:',
    appversion: 'App versie:',
    update: 'Laatst bijgewerkt:',
    blank: 'geen recente activiteiten',
    unbindSuc: 'Ontkoppelen gelukt!',
    unbindFail: 'Ontkoppelen mislukt!',
    ReboundSuc: 'Apparaatbinding wijzigen geslaagd!',
    ReboundFail: 'Koppeling van apparaat wijzigen mislukt!',
    noPlace: 'Geen apparaat om de binding te veranderen!',
    beExpOne: 'Uw apparaattoestemming verloopt over',
    beExpTwo: 'dagen',
    beExpThree:
      ', en de bewaakte apparaatgegevens worden na het verstrijken niet gesynchroniseerd. ',
    expiredOne:
      'Je apparaattoestemming is verlopen. De eerder verzamelde gegevens worden gewist ',
    expiredTwo:
      '. Na vernieuwing worden de bewaakte apparaatgegevens verder gesynchroniseerd.',
    renew: 'Verleng nu',

    insContact: 'Topcontacten op Instagram (binnen 7 dagen)',
    insCall: 'Topgesprekken op Instagram',
  },
  payPage: {
    title: 'Famikeep-prijzen en abonnementen',
    dec: 'WhatsApp monitoren met Famikeep is makkelijker dan je denkt!',
    bigSave: 'GROTE OPSLAG',
    one: '1-maand abonnement',
    three: '3-maanden abonnement',
    year: '1-jarig abonnement',
    toBuy: 'Nu kopen',
    auto: 'Automatische vernieuwing',
    cancelAny: 'Altijd annuleren',
    update: 'Gegevens automatisch om de vijf minuten bijwerken',
    collect: 'Verzamel alle gesprekken',
    interaction: 'Betere interactieve ervaring',
    hide: 'Sluipmodus',
  },
  pop: {
    changeBound: 'Apparaat Binding wijzigen',
    reboundText1: 'Weet u zeker dat u de',
    reboundText2:
      '-apparaatbinding wilt wijzigen en deze aan de ongebonden apparaattoestemming wilt binden om door te gaan met het verzamelen van gegevens (vervaldatum:',
    reboundText3: ')?',
    noRebound: 'Geen beschikbare apparaatrechten. Koop het alsjeblieft eerst.',
    goPay: 'Nu kopen',
    reboundList: 'Gebonden apparaat',
    reboundDec:
      'De volgende apparaattoestemming is verlopen. Selecteer een apparaat en koppel het aan de momenteel ongebonden apparaattoestemming om door te gaan met het verzamelen van gegevens.',
    rebounding: 'Apparaatbinding wijzigen...',
    reboundFail1: 'Kan de',
    reboundFail2:
      '-apparaatbinding niet wijzigen. Wil je het opnieuw proberen?',
    tryAgain: 'Opnieuw proberen',
    unbindTitle: 'Apparaat loskoppelen',
    unbindText1: 'Weet u zeker dat u het',
    unbindText2:
      '-apparaat loskoppelt? Alle verzamelde gegevensinformatie wordt gewist na ontbinding.',
    remind: 'Vervaldatum prompt',
    expiredPay: 'Vernieuwen',
    cancel: 'Annuleren',
    yes: 'Ja',
    no: 'Nee',
  },
  navTop: {
    langTitle: 'Taal wijzigen',
  },
  famikeep200: {
    Calls: 'Panggilan',
    Messages: 'Informatie',
    Contacts: 'Contactpersoon',
    Photos: 'Foto',
    Applications: 'Sollicitatie',
    'Compared to yesterday': 'vergeleken met gisteren',
    'All locations': 'Alle locaties',
    'Weekly average usage time': 'Gemiddelde tijd besteed deze week',
    'Floating comparison with last week': 'Zwevend vergeleken met vorige week',
    Average: 'gemiddeld',
    Mon: 'ma',
    Tue: 'di',
    Wed: 'wo',
    Thu: 'do',
    Fri: 'vr',
    Sat: 'za',
    Sun: 'zo',
    'Most used applications': 'meest gebruikte apps',
    Geolocation: 'geografische locatie',
    'Last updated': 'update tijd',
    'Geolocation permission':
      "Izin lokasi APP 'System Service' tidak diaktifkan, dan akuisisi lokasi gagal",
    Geofence: 'Geofence',
    'Create a geofence':
      'Maak geofences en u wordt per e-mail gewaarschuwd wanneer uw kind de geofence betreedt of verlaat.',
    'enters/exits': 'de geofence betreedt of verlaat.',
    'Create a new geofence': 'Maak een nieuwe geofence aan',
    'Geofence name': 'geofence naam',
    'Set geofence location': 'Stel de locatie van het hek in',
    'Set radius': 'ingestelde straal',
    'Set monitoring time': 'Stel de bewakingstijd in',
    'please choose': 'Gelieve te kiezen',
    Daily: 'Elke dag',
    'Every Monday': 'Elke maandag',
    'Every Tuesday': 'Elke dinsdag',
    'Every Wednesday': 'Elke woensdag',
    'Every Thursday': 'Elke donderdag',
    'Every Friday': 'Elke vrijdag',
    'Every Saturday': 'Elke zaterdag',
    'Every Sunday': 'Elke zondag',
    'Send alerts via email': 'E-mail de waarschuwing',
    'We will send alert emails to the registered email':
      'We sturen de waarschuwingsmail naar de geregistreerde mailbox',
    Save: 'redden ',
    'Please enter a geofence name': 'Voer een geofence-naam in',
    'Please select a geofence location': 'Selecteer de omheiningslocatie',
    Entered: 'Binnengekomen',
    Exited: 'Vertrokken',
    Edit: 'Bewerken',
    Delete: 'Verwijderen',
    'Are you sure you want to delete':
      'Weet u zeker dat u de xxx omheining wilt verwijderen? De bijbehorende monitoringsgegevens worden ook verwijderd.',
    'Are you sure you want to close':
      'Weet u zeker dat u wilt sluiten? U ontvangt geen e-mailwaarschuwingen bij het betreden/verlaten van de geofence',
    'Call history': 'Oproepgeschiedenis',
    Status: 'Status',
    Number: 'Nummer',
    Duration: 'Duur',
    Date: 'Datum',
    incoming: 'Inkomende oproep',
    outgoing: 'Uitgaande oproep',
    missed: 'Gemiste oproep',
    'no answered': 'Geen antwoord',
    Address: 'Adres',
    'Added time': 'Toevoegdatum',
    'Sort by name': 'Sorteer op naam',
    'Sort by time': 'Sorteer op tijd',
    'Please note that photos':
      "Let op, foto's worden alleen geüpload wanneer het apparaat verbonden is met WIFI",
    Album: 'Album',
    'Select all photos on the current page':
      "Selecteer alle foto's op deze pagina",
    'Previous page': 'Vorige',
    'Next page': 'Volgende',
    Back: 'Terug',
    'confirm to delete':
      'bevestigen om te verwijderen? Gegevens kunnen na verwijdering niet worden hersteld',
    Download: 'downloaden',
    Calendar: 'Agenda',
    Title: 'Titel',
    Description: 'Beschrijving',
    Location: 'Locatie',
    'Start time': 'Starttijd',
    'End time': 'Eindtijd',
    'Installed applications': 'Geïnstalleerde applicaties',
    'All applications': 'Alle applicaties',
    Name: 'Naam',
    Version: 'Versie',
    Size: 'Grootte',
    'Installation date': 'Installatiedatum',
    'Disable applications': 'Applicatie uitschakelen',
    'Seted disable rules': 'Ingestelde uitschakelingsregel',
    'Undisabled applications': 'Niet-uitgeschakelde applicaties',
    'Click the button below':
      'Klik op de knop hieronder om een uitschakelingsregel voor apps te maken en selecteer de apps die u wilt uitschakelen.',
    'Set disable rules': 'Uitschakelingsregel instellen',
    'Disable permanently': 'Altijd uitschakelen',
    'Disable ': 'Uitschakelen',
    'Custom disable time': 'Aangepaste uitschakeltijd',
    'Disable period': 'Uitschakelingsperiode',
    From: 'Van',
    To: 'Tot',
    Total: 'Totaal',
    Weekly: 'wekelijks',
    Time: 'tijd',
    'Select the applications you want to disable':
      'Selecteer de apps die u wilt uitschakelen',
    'Please select an application below': 'Selecteer hieronder een toepassing',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Weet u zeker dat u wilt verwijderen? Apps onder deze uitschakelingsregel werken niet meer.',
    'Disable options:': 'Uitschakelingsmethode',
    'one Disable permanently':
      '1. Altijd uitschakelen: De app wordt volledig uitgeschakeld. Uw kind kan de uitgeschakelde app niet gebruiken totdat u de uitschakelingsregel opheft.',
    'two Custom disable time':
      '2. Aangepaste uitschakeltijd: De regel wordt alleen geactiveerd binnen een specifieke tijdsperiode. Buiten deze periode kan uw kind de app normaal gebruiken.',
    'You can only choose one of the two setting options':
      'U kunt slechts één van de twee instellingen selecteren',
    complete: 'compleet',
    'Screen usage time': 'Schermtijd',
    'Screen Usage Time for xxx': 'Schermtijd voor xxx',
    'Frequently used applications': 'Veelgebruikte apps',
    Frequency: 'Frequentie',
    'Usage time': 'Gebruikstijd',
    'Screen disable': 'Scherm uitschakelen',
    'Turn on the switch and set the screen lock duration.':
      'Zet de schakelaar aan en stel de duur van het schermvergrendeling in.',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'Het scherm van het apparaat van uw kind wordt uitgeschakeld en kan na verloop van tijd worden hersteld.',
    'Disable all screen activities': 'Schakel alle schermactiviteiten uit',
    'Set screen disable duration':
      'Stel de duur van het schermvergrendeling in',
    'You can lift the disablement at any time':
      'U kunt de uitschakeling op elk moment opheffen',
    'Remaining screen disable time on the target device':
      'Resttijd van het scherm op het doelapparaat dat is uitgeschakeld',
    'Are you sure you want to lift the screen disablement now?':
      'Weet u zeker dat u het scherm nu wilt inschakelen?',
    Yes: 'Bevestigen',
    'This account does not exist, please register first':
      'Dit account bestaat niet, gelieve u eerst te registreren',
    'Synchronization successful, no new data':
      'Synchronisatie geslaagd, geen nieuwe gegevens',
    'Location alert': 'Locatie waarschuwing',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'Het door u bewaakte xxx apparaat is op xxxx-xx-xx binnengekomen bij xx.',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'Het xxx apparaat dat u volgt heeft xx verlaten op xxxx-xx-xx.',
    'View more location records:': 'Bekijk meer locatiegegevens:',
    'Thank you!': 'Bedankt!',
    'xxx Team': 'Team xxx',
    'When obtaining this location':
      'Bij het verkrijgen van deze locatie activeerde het apparaat virtuele positionering, wat tot onnauwkeurigheden kan leiden.',
    'The demo mode does not support this feature, please bind the device first.':
      'De demo-modus ondersteunt deze functie niet, bind eerst het apparaat.',
    'Location Tracking': 'Locatie Tracking',
    'General Application': 'Algemene Applicatie',
    'App Activites Tracking': 'App Activiteiten Tracking',
    'Screen Time Tracking': 'Schermtijd Tracking',
    'Social Application': 'Sociale Applicatie',
    'Subject:': 'Onderwerp:',
  },
  famikeep210: {
    'iCloud used storage': 'Gebruikte iCloud-opslag',
    Online: 'Online',
    Offline: 'Offline',
    'Last known location': 'Laatst bekende locatie',
    'Please bind your device': 'Bind alstublieft uw apparaat',
    'Log in iCloud to binding.':
      'Log in met het iCloud-account van het doelapparaat om de koppeling te voltooien.',
    'Please enter your Apple ID': 'Voer uw Apple ID in',
    'Please enter your password': 'Voer uw wachtwoord in',
    'Your entered account or password is incorrect.':
      'Het ingevoerde account of wachtwoord is onjuist.',
    'Ensure Apple ID and password':
      'Zorg ervoor dat u de Apple ID en het wachtwoord van het doelapparaat invoert, authenticatie uitvoert en de koppeling voltooit.',
    'Forgot Apple ID or password?': 'Apple ID of wachtwoord vergeten?',
    'Devices using the same iCloud account will be bound':
      'Apparaten die dezelfde iCloud-account gebruiken, worden gekoppeld.',
    'Two-Factor Authentication': 'Tweestapsverificatie',
    'A message has been sent':
      'Er is een bericht met een verificatiecode naar uw apparaat gestuurd. Voer de verificatiecode in om door te gaan.',
    'A message containing a verification code has been sent to xxx.':
      'Een bericht met een verificatiecode is naar xxx gestuurd. Voer de verificatiecode in om door te gaan.',
    'Incorrect verification code': 'Ongeldige verificatiecode',
    'Resend Verification Code': 'Verificatiecode opnieuw verzenden',
    'Get SMS Verification Code': 'SMS-verificatiecode ophalen',
    'Please select a phone number to receive the verification code.':
      'Selecteer alstublieft een telefoonnummer om de verificatiecode te ontvangen.',
    'Please select': 'Kies alstublieft',
    'The current iCloud has expired':
      'De huidige iCloud-accountverificatie is verlopen. Log opnieuw in op uw iCloud-account om gegevens te synchroniseren.',
    'Go to verification >>': 'Ga naar verificatie >>',
    'Data synchronization failed, iCloud account expired':
      'Gegevenssynchronisatie mislukt, iCloud-account verlopen, ga terug naar de startpagina en verifieer opnieuw.',
    'Created geofences': 'Aangemaakte geofences',
    'No geofences created': 'Geen geofences gemaakt',
    'FamiKeep - Parental Control App Pricing & Plans':
      'FamiKeep - Ouderlijk toezicht App Prijzen & Plannen',
    '30-Day Money Back Guarantee': '30-dagen geld-terug-garantie',
    'Professional Customer Support': 'Professionele klantenservice',
    'All Prices Exclusive of VAT': 'Alle prijzen exclusief BTW',
    '100% Secure': '100% Veilig',
    'More Features to Keep Your Kids Safe Online':
      'Meer functies om uw kinderen online veilig te houden',
    Features: 'Kenmerken',
    'Monitor App': 'Monitoringapp',
    'Type of Data': 'Soort gegevens',
    'WhatsApp Chat': 'WhatsApp Sohbet',
    'App Activity': 'Uygulama Etkinliği',
    'App Blocker': 'Uygulama Engelleyici',
    'Monitor Social Apps': 'Sociale apps monitoren',
    'Location History': 'Locatiegeschiedenis',
    'Location Reminder': 'Locatieherinnering',
    'Live Location': 'Live-locatie',
    'Safe Search': 'Veilige zoekopdracht',
    'Web Filter': 'Webfilter',
    'Browser History': 'Browsergeschiedenis',
    'Content Detection': 'Inhoudsdetectie',
    'All SMS': 'Alle SMS',
    Photos: "Foto's",
    Video: 'Video',
    Calendar: 'Agenda',
    Documents: 'Documenten',
    'Record Screen': 'Scherm',
    'Call History': 'Oproepgeschiedenis',
    'Call Recording': 'Gesprekken',
    Contacts: 'Contacten',
    Messages: 'Berichten',
    'Call Logs': 'Oproeplog',
    'Chat History': 'Chatgeschiedenis',
    'The device has been bound to other account':
      'Het apparaat is aan een ander account gekoppeld. Ontkoppel het eerst of neem contact op met de klantenservice om het te ontbinden.',
    Gallery: 'Galerij',
    'Hidden or Deleted Albums': 'Verborgen of verwijderde albums',
    'Recent Projects': 'Recente Projecten',
    'Personal Collection': 'Persoonlijk',
    'Recently Deleted': 'Recent verwijderd',
    'Live Photos': 'Live foto',
    'Exposure Photos': 'Belichtingsfoto',
    'Panoramic Photos': 'Panoramafoto',
    'Time-Lapse Photography': 'Tijdsverloopfotografie',
    'Slow Motion': 'Slow motion',
    'Depth Photos': 'Dieptefoto',
    'Burst Mode': 'Continu fotograferen',
    Screenshot: 'Schermopname',
    Motion: 'Dynamisch',
    Hide: 'Verbergen',
  },
  // 用户模块
  language: 'Taal',
  signIn: 'Log in',
  email: 'E-mail',
  password: 'Wachtwoord',
  sendCode: 'Code verzenden',

  // 登录
  signingIn: 'Inloggen',
  noAccount: 'Geen account?',
  signUpNow: 'Schrijf je nu in',
  forgetPassword: 'Wachtwoord vergeten?',

  // 注册
  signUp: 'Aanmelden',
  createAccount: 'Maak account',
  emVerificationCode: 'E-mailverificatiecode',
  creatingAccount: 'Creëren van een account…',
  alreadyHaveAccount: 'Heb je al een account?',
  SignInUp: 'Log in',
  send: 'Verzonden',
  sing: 's',
  byCreatingAnAccount: 'Door een account aan te maken, gaat u akkoord met de ',
  termsOfService: ' Servicevoorwaarden',
  and: ' en ',
  privacyPolicy: 'Privacybeleid.',

  // 忘记密码
  retrievePassword: 'Wachtwoord ophalen',
  backTo: 'Terug naar Inloggen',
  newPassword: 'nieuw Wachtwoord',
  resetPassword: 'Wachtwoord opnieuw instellen',
  loginSucceeded: 'Inloggen gelukt!',
  logoutSucceeded: 'Uitloggen gelukt!',

  // 用户模块异常提示
  passwordMmustBe8:
    'Wachtwoord moet 8-20 tekens lang zijn en een combinatie van letters en cijfers',
  pleaseEnterPassword: 'Voer uw accountwachtwoord in',
  pleaseEnterEmailAddress: 'Vul alstublieft uw e-mail adres in',
  invalidEmailFormat: 'ongeldige email formaat',
  pleaseEnterVerifiCode: 'Vul alstublieft de verificatiecode in',
  incorrectVerifiCode: 'Onjuiste verificatiecode',

  // Toast文案
  verificationCodeHasBeen: 'Verificatiecode is verzonden, controleer uw e-mail',
  pleaseReadAndAgree:
    'Lees en ga akkoord met de Servicevoorwaarden en het Privacybeleid.',
  networkAbnormal: 'Netwerk abnormaal',
  emailRegistered: 'De e-mail is niet geregistreerd, registreer eerst',
  passwordNotold:
    'Uw nieuwe wachtwoord mag niet hetzelfde zijn als het oude wachtwoord',
  invalidPasswordFormat: 'ongeldig wachtwoord formaat',
  theEmailHasRegistered: 'De e-mail is geregistreerd',
  verificationCodeGetAgain:
    'De verificatiecode is verlopen, vraag deze opnieuw aan',
  erificationCodeMatch: 'E-mail en verificatiecode komen niet overeen',
  incorrectPasswordOrEmail:
    'Onjuist wachtwoord of e-mailadres, voer opnieuw in',
  requestError: 'Verzoek fout',
  pleaseTryAgainLater: 'Te veel verzoeken, probeer het later opnieuw',
  accessDenied: 'Toegang geweigerd',
  requestErrorResourceNotFound: 'Verzoekfout, de bron is niet gevonden.',
  serverError: 'Serverfout',
  connectionServerFailed: 'Verbindingsserver mislukt',
  requestTimedOut: 'Verzoek time-out',
  networkAbnormaled: 'Netwerk abnormaal',
  serviceUnavailable: 'Service onbeschikbaar',
  httpVersioSupportTheRequest: 'De http-versie ondersteunt het verzoek niet',
  wrongPasswordTime:
    'U heeft 5 keer het verkeerde wachtwoord ingevoerd. Uw account is tijdelijk vergrendeld en u kunt niet inloggen. Probeer het over 10 minuten opnieuw.',
};
