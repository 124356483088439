export const TH = {
  chat: {
    pictureText: '[รูปภาพ]',
    videoText: '[วิดีโอ]',
    audioText: '[เสียง]',
    contactCard: '[บัตรติดต่อ]',
    location: '[ที่ตั้ง]',
    other: '[อื่น]',
    all: 'ทั้งหมด',
    today: 'วันนี้',
    yesterday: 'เมื่อวาน',
    last7Days: '7 วันที่ผ่านมา',
    last30Days: '30 วันที่ผ่านมา',
    customRange: 'เลือกช่วงที่กำหนดเอง',
    cancel: 'ยกเลิก',
    apply: 'นำมาใช้',
    pushNotify: 'การแจ้งเตือนแบบพุช',
    numbers: 'ตัวเลข',
    emails: 'อีเมล',
    outgoingCalls: 'การโทรออก',
    incomingCalls: 'สายเรียกเข้า',
    missedCalls: 'สายที่ไม่ได้รับ',
    answeredCalls: 'รับสาย',
    noAnswer: 'ไม่มีคำตอบ',
    canceledCalls: 'การโทรที่ยกเลิก',
    loadingText: 'กำลังโหลด โปรดอดใจรอ...',
    noMoreText: 'ไม่มีเนื้อหาเพิ่มเติม',
    noInfoText: 'ไม่มีข้อมูลกิจกรรม โปรดตรวจสอบอีกครั้งในภายหลัง',
    noAddressText: 'ไม่มีข้อมูลสมุดที่อยู่ โปรดตรวจสอบอีกครั้งในภายหลัง',
    noCallText: 'ไม่มีบันทึกการโทร โปรดตรวจสอบอีกครั้งในภายหลัง',

    dateSelect: 'เลือกวันที่',
    sentFile: 'ส่งไฟล์',
    sentAudio: 'ส่งเสียง',
    unGetFile: 'ไม่สามารถรับไฟล์ได้',
    unGetAudio: 'ไม่สามารถรับเสียง',

    syncFailed: 'การซิงโครไนซ์ข้อมูลล้มเหลว!',
    syncSuccessed: 'การซิงโครไนซ์ข้อมูลสำเร็จ!',
    unknownContact: 'ผู้ติดต่อที่ไม่รู้จัก',
    noSyncNotice1: 'ข้อมูลไม่ได้รับการซิงโครไนซ์นานกว่า ',
    noSyncNotice2: '24 ชั่วโมง',
    noSyncNotice3:
      ' โปรดตรวจสอบให้แน่ใจว่าโทรศัพท์เป้าหมายเปิดอยู่และเชื่อมต่อกับอินเทอร์เน็ต และแอป "System Service" ทำงานตามปกติ',
    sync: 'ซิงค์',
    startDate: 'วันที่เริ่มต้น',
    endDate: 'วันที่สิ้นสุด',
    noSupportToast: 'ไม่รองรับการกรองวันที่ในการสาธิต',
    unknowVideo:
      'ข้อความวิดีโอ ไม่มีรายละเอียดเนื่องจากนโยบายความเป็นส่วนตัวของ Instagram',
    unknowImage:
      'ข้อความรูปภาพ ไม่มีรายละเอียดเนื่องจากนโยบายความเป็นส่วนตัวของ Instagram',
    unknowAudio:
      'ข้อความเสียงไม่มีรายละเอียดเนื่องจากนโยบายความเป็นส่วนตัวของ Instagram',
    outgoingVoice: 'โทรออกเสียง',
    incomingVoice: 'โทรเข้าเสียง',
    outgoingVideo: 'วิดีโอคอลขาออก',
    incomingVideo: 'โทรเข้าวิดีโอโทร',
  },
  user: {
    userProfile: 'ประวัติผู้ใช้',
    usesrname: 'ชื่อผู้ใช้:',
    edit: 'แก้ไข',
    email: 'อีเมล:',
    permission: 'ข้อมูลการอนุญาต',
    bounded: 'อุปกรณ์ที่ถูกผูกไว้:',
    restDevice: 'อุปกรณ์ที่เหลืออยู่:',
    expire: 'วันหมดอายุ:',
    expired: 'xxxx-xx-xx (หมดอายุ)',
    logout: 'ออกจากระบบ',
    changename: 'เปลี่ยนชื่อผู้ใช้',
    save: 'บันทึก',
    inputname: 'กรุณากรอกชื่อผู้ใช้',
    nameNull: 'รูปแบบชื่อผู้ใช้ผิดปกติ กรุณากรอกใหม่อีกครั้ง',
    networkError: 'ไม่สามารถเปลี่ยนชื่อผู้ใช้ โปรดตรวจสอบเครือข่ายของคุณ',
    nameSuccess: 'ชื่อผู้ใช้เปลี่ยนเรียบร้อยแล้ว',
    changeavatar: 'เปลี่ยนรูปประจำตัว',
    loading: 'กำลังโหลด…',
    changeAvatar: 'เปลี่ยน',
    supported: 'รองรับรูปแบบ jpg, jpeg, png และขนาดไม่เกิน 2M',
    maxsize: 'ขนาดภาพต้องไม่เกิน 2M',
    avatarError: 'ไม่สามารถเปลี่ยนอวาตาร์ โปรดตรวจสอบเครือข่ายของคุณ',
    avatarSuccess: 'เปลี่ยนอวาตาร์เรียบร้อยแล้ว',
    imgError: 'ข้อผิดพลาดของภาพ',
    imageType: 'ไม่รองรับประเภทรูปภาพ',
    nameEmpty: 'ชื่อผู้ใช้ไม่สามารถเว้นว่างได้',
  },
  leftTab: {
    device: 'อุปกรณ์',
    unbound: 'อุปกรณ์ที่ไม่ได้ผูกไว้',
    addDevice: 'เพิ่มอุปกรณ์',
    dashboard: 'แผงควบคุม',
    whatsapp: 'WhatsApp',
    instagram: 'Instagram',
    bindRemind: 'กรุณาผูกอุปกรณ์ก่อน',
    payRemind: 'กรุณาซื้อก่อน',
    purchase: 'ซื้อเลย',
    unboundName: 'ผูกเครื่องเลย',
    toBuy: 'ซื้ออุปกรณ์',
  },
  guide: {
    title: 'กรุณาผูกอุปกรณ์ของคุณ',
    oneT: '1. รับอุปกรณ์เป้าหมาย',
    oneContent:
      'ในการตรวจสอบอุปกรณ์ คุณต้องได้รับอุปกรณ์ตรวจสอบเป้าหมายเป็นเวลาประมาณ 10 นาที',
    twoT: '2. ดาวน์โหลดและติดตั้งแอพ',
    twoContent1: 'ป้อน',
    twoContent2: 'ในเบราว์เซอร์อุปกรณ์เป้าหมาย จากนั้นดาวน์โหลดและติดตั้งแอป',
    threeT: '3. ตั้งค่าการอนุญาตอุปกรณ์',
    threeContent:
      'ทำตามคำแนะนำในแอปเพื่อตั้งค่าการอนุญาตอุปกรณ์ หลังจากตั้งค่าแล้ว คุณสามารถเฝ้าสังเกตอุปกรณ์ได้',
    tips1:
      "เพื่อให้แน่ใจว่ามีการซ่อนตัว แอปจะถูกปลอมแปลงเป็นซอฟต์แวร์ระบบชื่อ 'System Service'",
    tips2:
      'เพื่อไม่ให้มีร่องรอย คุณสามารถลบบันทึกเบราว์เซอร์และแพ็คเกจการติดตั้งแอพหลังจากติดตั้งแอพสำเร็จ',
    tips3:
      "แอปจะแสดงเป็น 'System Service' บนเดสก์ท็อป คุณสามารถเลือกที่จะซ่อนมันในโฟลเดอร์เพื่อให้ได้เอฟเฟกต์การพรางตัวที่ดีกว่า",
    tips41:
      'เพื่อให้แน่ใจว่าคุณสามารถติดตั้งแอปและตั้งค่าการอนุญาตได้รวดเร็วยิ่งขึ้น คุณสามารถดู',
    tips42: 'ก่อนเพื่อเรียนรู้วิธีการทำ',
    detail: 'บทช่วยสอนโดยละเอียด',
    tips5:
      'หากการอนุญาตอุปกรณ์ที่ผูกไว้หมดอายุ คุณสามารถเปลี่ยนการเชื่อมโยงอุปกรณ์และผูกกับสิทธิ์เพื่อรวบรวมข้อมูลต่อไป',
    rebound: 'คลิกเพื่อเปลี่ยนการผูกอุปกรณ์',
  },
  dashboard: {
    contact: 'รายชื่อผู้ติดต่อยอดนิยมใน WhatsApp (ภายใน 7 วัน)',
    call: 'โทรยอดนิยมใน WhatsApp',
    view: 'ดูทั้งหมด',
    account: 'บัญชี',
    id: 'หมายเลขบัญชี:',
    expire: 'วันหมดอายุ:',
    bound: 'อุปกรณ์ที่ถูกผูกไว้:',
    restDevice: 'อุปกรณ์ที่เหลืออยู่:',
    target: 'เกี่ยวกับอุปกรณ์เป้าหมาย',
    brand: 'ยี่ห้อ:',
    model: 'แบบอย่าง:',
    version: 'เวอร์ชัน Android:',
    battery: 'แบตเตอรี่:',
    wifi: 'Wi-Fi:',
    appversion: 'เวอร์ชันแอพ:',
    update: 'อัปเดตล่าสุด:',
    blank: 'ไม่มีกิจกรรมล่าสุด',
    unbindSuc: 'เลิกผูกสำเร็จ!',
    unbindFail: 'เลิกผูกล้มเหลว!',
    ReboundSuc: 'เปลี่ยนการผูกอุปกรณ์สำเร็จ!',
    ReboundFail: 'เปลี่ยนการผูกอุปกรณ์ล้มเหลว!',
    noPlace: 'ไม่มีอุปกรณ์ที่จะเปลี่ยนการผูกมัด!',
    beExpOne: 'การอนุญาตอุปกรณ์ของคุณจะหมดอายุใน',
    beExpTwo: 'วัน',
    beExpThree: 'และข้อมูลอุปกรณ์ที่ถูกตรวจสอบจะไม่ซิงโครไนซ์หลังจากหมดอายุ',
    expiredOne:
      'การอนุญาตอุปกรณ์ของคุณหมดอายุแล้ว ข้อมูลที่รวบรวมไว้ก่อนหน้านี้จะถูกล้างใน',
    expiredTwo:
      'หลังจากการต่ออายุ ข้อมูลอุปกรณ์ที่ได้รับการตรวจสอบจะยังคงซิงโครไนซ์ต่อไป',
    renew: 'ต่ออายุเลย',

    insContact: 'ผู้ติดต่อยอดนิยมบน Instagram (ภายใน 7 วัน)',
    insCall: 'การโทรยอดนิยมบน Instagram',
  },
  payPage: {
    title: 'ราคาและแผนของ Famikeep',
    dec: 'การตรวจสอบ WhatsApp ด้วย Famikeep นั้นง่ายกว่าที่คุณคิด!',
    bigSave: 'คุ้มสุด',
    one: 'แผน 1 เดือน',
    three: 'แผน 3 เดือน',
    year: 'แผน 1 ปี',
    toBuy: 'ซื้อเลย',
    auto: 'ต่ออายุอัตโนมัติ',
    cancelAny: 'ยกเลิกได้ตลอดเวลา',
    update: 'อัปเดตข้อมูลโดยอัตโนมัติทุก ๆ ห้านาที',
    collect: 'รวบรวมบทสนทนาทั้งหมด',
    interaction: 'ประสบการณ์การโต้ตอบที่ดีขึ้น',
    hide: 'โหมดซ่อนตัว',
  },
  pop: {
    changeBound: 'เปลี่ยนการผูกอุปกรณ์',
    reboundText1: 'คุณแน่ใจหรือไม่ว่าจะเปลี่ยนการผูกอุปกรณ์',
    reboundText2:
      'และผูกกับสิทธิ์ของอุปกรณ์ที่ไม่ได้ผูกไว้เพื่อดำเนินการรวบรวมข้อมูลต่อไป (วันที่หมดอายุ:',
    reboundText3: ')',
    noRebound: 'ไม่มีการอนุญาตอุปกรณ์ที่ใช้ได้ กรุณาซื้อมันก่อน',
    goPay: 'ซื้อเลย',
    reboundList: 'อุปกรณ์ที่ถูกผูกไว้',
    reboundDec:
      'การอนุญาตอุปกรณ์ต่อไปนี้หมดอายุแล้ว เลือกอุปกรณ์และผูกกับสิทธิ์อุปกรณ์ที่ไม่ได้ผูกไว้ในปัจจุบันเพื่อรวบรวมข้อมูลต่อไป',
    rebounding: 'กำลังเปลี่ยนการผูกอุปกรณ์...',
    reboundFail1: 'ไม่สามารถเปลี่ยนการเชื่อมโยงอุปกรณ์',
    reboundFail2: 'คุณต้องการที่จะลองอีกครั้ง?',
    tryAgain: 'ลองอีกครั้ง',
    unbindTitle: 'เลิกผูกอุปกรณ์',
    unbindText1: 'คุณแน่ใจหรือว่าเลิกผูกอุปกรณ์',
    unbindText2: '? ข้อมูลที่เก็บรวบรวมทั้งหมดจะถูกล้างหลังจากเลิกผูก',
    remind: 'ข้อความแจ้งการหมดอายุ',
    expiredPay: 'ต่ออายุ',
    cancel: 'ยกเลิก',
    yes: 'ใช่',
    no: 'ไม่',
  },
  navTop: {
    langTitle: 'เปลี่ยนภาษา',
  },
  famikeep200: {
    Calls: 'โทร',
    Messages: 'ข้อความ',
    Contacts: 'ผู้ติดต่อ',
    Photos: 'รูปถ่าย',
    Applications: 'แอพพลิเคชั่น',
    'Compared to yesterday': 'เทียบกับเมื่อวาน',
    'All locations': 'สถานที่ทั้งหมด',
    'Weekly average usage time': 'เวลาใช้งานเฉลี่ยรายสัปดาห์',
    'Floating comparison with last week':
      'การเปรียบเทียบแบบลอยตัวกับสัปดาห์ที่แล้ว',
    Average: 'เฉลี่ย',
    Mon: 'จ.',
    Tue: 'อ.',
    Wed: 'อ.',
    Thu: 'พฤ.',
    Fri: 'ศ.',
    Sat: 'ส.',
    Sun: 'อา.',
    'Most used applications': 'แอปพลิเคชั่นที่ใช้มากที่สุด',
    Geolocation: 'ตำแหน่งทางภูมิศาสตร์',
    'Last updated': 'ปรับปรุงล่าสุด',
    'Geolocation permission':
      "สิทธิ์ระบุตำแหน่งทางภูมิศาสตร์สำหรับแอป 'บริการระบบ' ถูกปิดใช้งาน ดึงข้อมูลตำแหน่งไม่สำเร็จ",
    Geofence: 'Geofence',
    'Create a geofence':
      'สร้าง geofence เพื่อรับการแจ้งเตือนทางอีเมลเมื่อลูกของคุณเข้า/ออกจากพื้นที่ที่กำหนด',
    'enters/exits': 'ของคุณเข้า/ออกจากพื้นที่ที่กำหนด',
    'Create a new geofence': 'สร้าง geofence ใหม่',
    'Geofence name': 'ชื่อ Geofence',
    'Set geofence location': 'ตั้งค่าตำแหน่ง geofence',
    'Set radius': 'กำหนดรัศมี',
    'Set monitoring time': 'ตั้งเวลาตรวจสอบ',
    'please choose': 'กรุณาเลือก',
    Daily: 'รายวัน',
    'Every Monday': 'ทุกวันจันทร์',
    'Every Tuesday': 'ทุกวันอังคาร',
    'Every Wednesday': 'ทุกวันพุธ',
    'Every Thursday': 'ทุกวันพฤหัสบดี',
    'Every Friday': 'ทุกวันศุกร์',
    'Every Saturday': 'ทุกวันเสาร์',
    'Every Sunday': 'ทุกวันอาทิตย์',
    'Send alerts via email': 'ส่งการแจ้งเตือนทางอีเมล',
    'We will send alert emails to the registered email':
      'เราจะส่งอีเมลแจ้งเตือนไปยังอีเมลที่ลงทะเบียนไว้',
    Save: 'บันทึก',
    'Please enter a geofence name': 'โปรดป้อนชื่อ Geofence',
    'Please select a geofence location': 'โปรดเลือกตำแหน่ง geofence',
    Entered: 'ป้อน',
    Exited: 'ออก',
    Edit: 'แก้ไข',
    Delete: 'ลบ',
    'Are you sure you want to delete':
      'คุณแน่ใจหรือไม่ว่าต้องการลบ xxx geofence ข้อมูลการตรวจสอบที่เกี่ยวข้องจะถูกลบด้วย',
    'Are you sure you want to close':
      'คุณแน่ใจหรือไม่ว่าต้องการปิด คุณจะไม่ได้รับอีเมลแจ้งเตือนเมื่อเข้า/ออกจากขอบเขตภูมิศาสตร์',
    'Call history': 'ประวัติการโทร',
    Status: 'สถานะ',
    Number: 'ตัวเลข',
    Duration: 'ระยะเวลา',
    Date: 'วันที่',
    incoming: 'สายเรียกเข้า',
    outgoing: 'โทรออก',
    missed: 'สายที่ไม่ได้รับ',
    'no answered': 'ไม่มีคำตอบ',
    Address: 'ที่อยู่',
    'Added time': 'เพิ่มเวลา',
    'Sort by name': 'เรียงตามชื่อ',
    'Sort by time': 'เรียงตามเวลา',
    'Please note that photos':
      'โปรดทราบว่ารูปภาพจะถูกอัปโหลดเมื่ออุปกรณ์เชื่อมต่อกับ Wi-Fi เท่านั้น',
    Album: 'อัลบั้ม',
    'Select all photos on the current page': 'เลือกรูปภาพทั้งหมดในหน้าปัจจุบัน',
    'Previous page': 'หน้าก่อนหน้า',
    'Next page': 'หน้าต่อไป',
    Back: 'กลับ',
    'confirm to delete': 'ยืนยันการลบ? ไม่สามารถกู้คืนข้อมูลได้หลังจากการลบ',
    Download: 'ดาวน์โหลด',
    Calendar: 'ปฏิทิน',
    Title: 'ชื่อ',
    Description: 'คำอธิบาย',
    Location: 'ที่ตั้ง',
    'Start time': 'เวลาเริ่มต้น',
    'End time': 'เวลาสิ้นสุด',
    'Installed applications': 'แอปพลิเคชั่นที่ติดตั้ง',
    'All applications': 'แอปพลิเคชันทั้งหมด',
    Name: 'ชื่อ',
    Version: 'รุ่น',
    Size: 'ขนาด',
    'Installation date': 'วันที่ติดตั้ง',
    'Disable applications': 'ปิดใช้งานแอปพลิเคชัน',
    'Seted disable rules': 'ตั้งกฎการปิดการใช้งาน',
    'Undisabled applications': 'แอปพลิเคชันที่ไม่ได้ปิดใช้งาน',
    'Click the button below':
      'คลิกปุ่มด้านล่างเพื่อสร้างกฎการปิดใช้งานแอป และเลือกแอปที่คุณต้องการปิดใช้งาน',
    'Set disable rules': 'ตั้งกฎการปิดการใช้งาน',
    'Disable permanently': 'ปิดการใช้งานอย่างถาวร',
    'Disable ': 'ปิดการใช้งาน',
    'Custom disable time': 'เวลาปิดใช้งานที่กำหนดเอง',
    'Disable period': 'ระยะเวลาปิดการใช้งาน',
    From: 'จาก',
    To: 'ถึง',
    Total: 'ทั้งหมด',
    Weekly: 'รายสัปดาห์',
    Time: 'เวลา',
    'Select the applications you want to disable':
      'เลือกแอปพลิเคชันที่คุณต้องการปิดใช้งาน',
    'Please select an application below': 'โปรดเลือกใบสมัครด้านล่าง',
    'Are you sure you want to delete? The disabled apps under this rule will no longer be affected':
      'คุณแน่ใจหรือว่าต้องการลบ? แอปที่ถูกปิดใช้งานภายใต้กฎนี้จะไม่ได้รับผลกระทบอีกต่อไป',
    'Disable options:': 'ปิดการใช้งานตัวเลือก:',
    'one Disable permanently':
      '1. ปิดใช้งานอย่างถาวร: ปิดใช้งานแอปพลิเคชันโดยสมบูรณ์ บุตรหลานของคุณจะไม่สามารถใช้แอปที่ปิดใช้งานได้จนกว่าคุณจะยกเลิกการปิดใช้งาน',
    'two Custom disable time':
      '2. เวลาปิดใช้งานแบบกำหนดเอง: เรียกใช้กฎการปิดใช้งานในช่วงเวลาที่กำหนดเท่านั้น และบุตรหลานของคุณสามารถใช้แอปได้ตามปกตินอกเวลาดังกล่าว',
    'You can only choose one of the two setting options':
      'คุณสามารถเลือกหนึ่งในสองตัวเลือกการตั้งค่าเท่านั้น',
    complete: 'สมบูรณ์',
    'Screen usage time': 'เวลาใช้งานหน้าจอ',
    'Screen Usage Time for xxx': 'เวลาใช้งานหน้าจอสำหรับ xxx',
    'Frequently used applications': 'แอพพลิเคชั่นที่ใช้บ่อย',
    Frequency: 'ความถี่',
    'Usage time': 'เวลาใช้งาน',
    'Screen disable': 'ปิดการใช้งานหน้าจอ',
    'Turn on the switch and set the screen lock duration.':
      'เปิดสวิตช์และตั้งระยะเวลาล็อกหน้าจอ',
    "Your child's device screen will be disabled and will be restored after the set time is consumed.":
      'หน้าจออุปกรณ์ของบุตรหลานของคุณจะถูกปิดใช้งานและจะถูกกู้คืนหลังจากเวลาที่ตั้งไว้หมดลง',
    'Disable all screen activities': 'ปิดใช้งานกิจกรรมหน้าจอทั้งหมด',
    'Set screen disable duration': 'ตั้งระยะเวลาปิดหน้าจอ',
    'You can lift the disablement at any time':
      'คุณสามารถยกความพิการได้ตลอดเวลา',
    'Remaining screen disable time on the target device':
      'เวลาปิดใช้งานหน้าจอที่เหลืออยู่บนอุปกรณ์เป้าหมาย',
    'Are you sure you want to lift the screen disablement now?':
      'แน่ใจไหมว่าต้องการยกเลิกหน้าจอตอนนี้',
    Yes: 'ใช่',
    'This account does not exist, please register first':
      'ไม่มีบัญชีนี้ กรุณาลงทะเบียนก่อน',
    'Synchronization successful, no new data':
      'การซิงโครไนซ์สำเร็จ ไม่มีข้อมูลใหม่',
    'Location alert': 'การแจ้งเตือนตำแหน่ง',
    "The xxx device you're monitoring entered xx on xxxx-xx-xx":
      'อุปกรณ์ xxx ที่คุณกำลังตรวจสอบป้อน xx เมื่อ xxxx-xx-xx',
    "The xxx device you're monitoring exited xx on xxxx-xx-xx":
      'อุปกรณ์ xxx ที่คุณกำลังตรวจสอบออกจาก xx เมื่อ xxxx-xx-xx',
    'View more location records:': 'ดูบันทึกตำแหน่งเพิ่มเติม:',
    'Thank you!': 'ขอบคุณ!',
    'xxx Team': 'xxx ทีม',
    'When obtaining this location':
      'เมื่อได้รับตำแหน่งนี้ อุปกรณ์จะเปิดใช้งานตำแหน่งเสมือน ซึ่งอาจนำไปสู่ความไม่ถูกต้อง',
    'The demo mode does not support this feature, please bind the device first.':
      'โหมดสาธิตไม่รองรับคุณสมบัตินี้ โปรดผูกอุปกรณ์ก่อน',
    'Location Tracking': 'การติดตามตำแหน่งที่ตั้ง',
    'General Application': 'แอปพลิเคชันทั่วไป',
    'App Activites Tracking': 'การติดตามกิจกรรมของแอปพลิเคชัน',
    'Screen Time Tracking': 'การติดตามเวลาบนหน้าจอ',
    'Social Application': 'แอปพลิเคชันสังคม',
    'Subject:': 'เรื่อง:',
  },
  famikeep210: {
    'iCloud used storage': 'พื้นที่จัดเก็บข้อมูลใน iCloud ที่ใช้',
    Online: 'ออนไลน์',
    Offline: 'ออฟไลน์',
    'Last known location': 'ตำแหน่งที่ทราบล่าสุด',
    'Please bind your device': 'โปรดผูกอุปกรณ์ของคุณ',
    'Log in iCloud to binding.':
      'เข้าสู่ระบบบัญชี iCloud ของอุปกรณ์เป้าหมายเพื่อการเชื่อมต่อ',
    'Please enter your Apple ID': 'โปรดใส่ Apple ID ของคุณ',
    'Please enter your password': 'โปรดใส่รหัสผ่านของคุณ',
    'Your entered account or password is incorrect.':
      'บัญชีหรือรหัสผ่านที่คุณป้อนไม่ถูกต้อง',
    'Ensure Apple ID and password':
      'ตรวจสอบให้แน่ใจว่าคุณป้อน Apple ID และรหัสผ่านของอุปกรณ์เป้าหมายและตรวจสอบความถูกต้องและเสร็จสิ้นการผูกมัด',
    'Forgot Apple ID or password?': 'ลืม Apple ID หรือรหัสผ่านหรือไม่?',
    'Devices using the same iCloud account will be bound':
      'อุปกรณ์ที่ใช้บัญชี iCloud เดียวกันจะถูกผูกมัด',
    'Two-Factor Authentication': 'การตรวจสอบสองขั้นตอน',
    'A message has been sent':
      'ข้อความที่มีรหัสยืนยันถูกส่งไปยังอุปกรณ์ของคุณ โปรดป้อนรหัสยืนยันเพื่อดำเนินการต่อ',
    'A message containing a verification code has been sent to xxx.':
      'ข้อความที่มีรหัสยืนยันถูกส่งไปยัง xxx โปรดป้อนรหัสยืนยันเพื่อดำเนินการต่อ',
    'Incorrect verification code': 'รหัสยืนยันไม่ถูกต้อง',
    'Resend Verification Code': 'ส่งรหัสยืนยันอีกครั้ง',
    'Get SMS Verification Code': 'รับรหัสยืนยันทาง SMS',
    'Please select a phone number to receive the verification code.':
      'โปรดเลือกหมายเลขโทรศัพท์เพื่อรับรหัสยืนยัน',
    'Please select': 'โปรดเลือก',
    'The current iCloud has expired':
      'การยืนยันบัญชี iCloud ปัจจุบันหมดอายุแล้ว โปรดเข้าสู่ระบบอีกครั้งเพื่อซิงโครไนส์ข้อมูล iCloud ของคุณ',
    'Go to verification >>': 'ไปที่การยืนยัน >>',
    'Data synchronization failed, iCloud account expired':
      'การซิงโครไนส์ข้อมูลล้มเหลว บัญชี iCloud หมดอายุ โปรดกลับไปที่หน้าแรกและทำการยืนยันใหม่',
    'Created geofences': 'โซนเชิงซีรี่ย์ที่สร้างขึ้น',
    'No geofences created': 'ไม่มีเซอร์เฟซที่สร้างขึ้น',
    'FamiKeep - Parental Control App Pricing & Plans':
      'FamiKeep - ราคาและแผนการใช้งานแอปควบคุมพ่อแม่',
    '30-Day Money Back Guarantee': 'ประกันคืนเงิน 30 วัน',
    'Professional Customer Support': 'ฝ่ายสนับสนุนลูกค้ามืออาชีพ',
    'All Prices Exclusive of VAT': 'ราคาไม่รวมภาษีมูลค่าเพิ่ม',
    '100% Secure': '100% ปลอดภัย',
    'More Features to Keep Your Kids Safe Online':
      'คุณลักษณะเพิ่มเติมเพื่อให้ลูกของคุณปลอดภัยออนไลน์',
    Features: 'คุณลักษณะ',
    'Monitor App': 'แอปพลิเคชันการตรวจสอบ',
    'Type of Data': 'ประเภทข้อมูล',
    'WhatsApp Chat': 'Perbualan WhatsApp',
    'App Activity': 'Aktiviti Aplikasi',
    'App Blocker': 'Pelumpuh Aplikasi',
    'Monitor Social Apps': 'การตรวจสอบแอปพลิเคชันโซเชียล',
    'Location History': 'ประวัติตำแหน่ง',
    'Location Reminder': 'การเตือนตำแหน่ง',
    'Live Location': 'สถานที่สด',
    'Safe Search': 'ค้นหาที่ปลอดภัย',
    'Web Filter': 'Web Filter',
    'Browser History': 'ประวัติเว็บ',
    'Content Detection': 'ตรวจจับเนื้อหา',
    'All SMS': 'ทุก SMS',
    Photos: 'รูปภาพ',
    Video: 'วิดีโอ',
    Calendar: 'ปฏิทิน',
    Documents: 'เอกสาร',
    'Record Screen': 'หน้าจอ',
    'Call History': 'ประวัติการโทร',
    'Call Recording': 'บันทึกการโทร',
    Contacts: 'ที่อยู่ติดต่อ',
    Messages: 'ข้อความ',
    'Call Logs': 'บันทึกการโทร',
    'Chat History': 'ประวัติแชท',
    'The device has been bound to other account':
      'อุปกรณ์ถูกผูกไว้กับบัญชีอื่น โปรดยกเลิกการผูกก่อนหรือติดต่อฝ่ายบริการลูกค้าเพื่อยกเลิกการผูก',
    Gallery: 'แกลเลอรี',
    'Hidden or Deleted Albums': 'อัลบั้มที่ซ่อนหรือถูกลบ',
    'Recent Projects': 'โครงการล่าสุด',
    'Personal Collection': 'ส่วนตัว',
    'Recently Deleted': 'ล่าสุดที่ถูกลบ',
    'Live Photos': 'รูปภาพสด',
    'Exposure Photos': 'ภาพการเปิด',
    'Panoramic Photos': 'รูปภาพพาโนรามา',
    'Time-Lapse Photography': 'ภาพย้อนหลัง',
    'Slow Motion': 'ย้อนกลับช้า',
    'Depth Photos': 'รูปถ่ายลึก',
    'Burst Mode': 'ถ่ายภาพต่อเนื่อง',
    Screenshot: 'การจับภาพหน้าจอ',
    Motion: 'ไดนามิก',
    Hide: 'ซ่อน',
  },
  // 用户模块
  language: 'ภาษา',
  signIn: 'เข้าสู่ระบบ',
  email: 'อีเมล',
  password: 'รหัสผ่าน',
  sendCode: 'ส่งรหัส',

  // 登录
  signingIn: 'เข้าสู่ระบบ',
  noAccount: 'ไม่มีบัญชี?',
  signUpNow: 'สมัครเลย',
  forgetPassword: 'ลืมรหัสผ่าน?',

  // 注册
  signUp: 'เข้าสู่ระบบ',
  createAccount: 'สร้างบัญชี',
  emVerificationCode: 'รหัสยืนยันอีเมล',
  creatingAccount: 'กำลังสร้าง  บัญชี…',
  alreadyHaveAccount: 'มีบัญชีอยู่แล้ว?',
  SignInUp: 'เข้าสู่ระบบ',
  send: 'ส่งแล้ว',
  sing: 'วินาที',
  byCreatingAnAccount: 'การสร้างบัญชีแสดงว่าคุณยอมรับ',
  termsOfService: 'เงื่อนไขการให้บริการ',
  and: ' และ ',
  privacyPolicy: 'นโยบายความเป็นส่วนตัว',

  // 忘记密码
  retrievePassword: 'ดึงรหัสผ่าน',
  backTo: 'กลับไปเข้าสู่ระบบ',
  newPassword: 'รหัสผ่านใหม่',
  resetPassword: 'รีเซ็ตรหัสผ่าน',
  loginSucceeded: 'เข้าสู่ระบบสำเร็จ!',
  logoutSucceeded: 'ออกจากระบบสำเร็จ!',

  // 用户模块异常提示
  passwordMmustBe8:
    'รหัสผ่านต้องมีความยาว 8-20 อักขระและประกอบด้วยตัวอักษรและตัวเลข',
  pleaseEnterPassword: 'กรุณาป้อนรหัสผ่านบัญชีของคุณ',
  pleaseEnterEmailAddress: 'กรุณาป้อนอีเมลของคุณ',
  invalidEmailFormat: 'รูปแบบอีเมลไม่ถูกต้อง',
  pleaseEnterVerifiCode: 'กรุณาป้อนรหัสยืนยัน',
  incorrectVerifiCode: 'รหัสยืนยันไม่ถูกต้อง',

  // Toast文案
  verificationCodeHasBeen: 'ส่งรหัสยืนยันแล้ว โปรดตรวจสอบอีเมลของคุณ',
  pleaseReadAndAgree:
    'โปรดอ่านและยอมรับเงื่อนไขการให้บริการและนโยบายความเป็นส่วนตัว',
  networkAbnormal: 'เครือข่ายผิดปกติ',
  emailRegistered: 'ยังไม่ได้ลงทะเบียนอีเมล โปรดลงทะเบียนก่อน',
  passwordNotold: 'รหัสผ่านใหม่ของคุณต้องไม่เหมือนกับรหัสผ่านเก่า',
  invalidPasswordFormat: 'รูปแบบรหัสผ่านไม่ถูกต้อง',
  theEmailHasRegistered: 'อีเมลได้รับการลงทะเบียนแล้ว',
  verificationCodeGetAgain: 'รหัสยืนยันหมดอายุ โปรดรับอีกครั้ง',
  erificationCodeMatch: 'อีเมลและรหัสยืนยันไม่ตรงกัน',
  incorrectPasswordOrEmail: 'รหัสผ่านหรืออีเมลไม่ถูกต้อง โปรดป้อนอีกครั้ง',
  requestError: 'คำขอผิดพลาด',
  pleaseTryAgainLater: 'คำขอมากเกินไป โปรดลองอีกครั้งในภายหลัง',
  accessDenied: 'ปฏิเสธการเข้าใช้',
  requestErrorResourceNotFound: 'คำขอผิดพลาด ไม่พบทรัพยากร',
  serverError: 'เซิร์ฟเวอร์ผิดพลาด',
  connectionServerFailed: 'การเชื่อมต่อเซิร์ฟเวอร์ล้มเหลว',
  requestTimedOut: 'คำขอหมดเวลา',
  networkAbnormaled: 'เครือข่ายผิดปกติ',
  serviceUnavailable: 'ไม่สามารถให้บริการได้',
  httpVersioSupportTheRequest: 'เวอร์ชัน http ไม่รองรับคำขอ',
  wrongPasswordTime:
    'คุณป้อนรหัสผ่านผิด 5 ครั้ง บัญชีของคุณถูกล็อคชั่วคราวและไม่สามารถเข้าสู่ระบบได้ โปรดลองอีกครั้งใน 10 นาที',
};
