import { CN } from "@/language/zh/index";
import { EN } from "@/language/en/index";
import { TW } from "@/language/tw/index";
import { AR } from "@/language/ar/index";
import { DE } from "@/language/de/index";
import { ES } from "@/language/es/index";
import { FR } from "@/language/fr/index";
import { ID } from "@/language/id/index";
import { IT } from "@/language/it/index";
import { JA } from "@/language/ja/index";
import { KO } from "@/language/ko/index";
import { MS } from "@/language/ms/index";
import { NL } from "@/language/nl/index";
import { PT } from "@/language/pt/index";
import { RU } from "@/language/ru/index";
import { TH } from "@/language/th/index";
import { TR } from "@/language/tr/index";

const messages = {
  "zh-CN": CN,
  "zh-TW": TW,
  en: EN,
  tr: TR,
  ar: AR,
  de: DE,
  es: ES,
  fr: FR,
  id: ID,
  it: IT,
  ja: JA,
  ko: KO,
  ms: MS,
  nl: NL,
  pt: PT,
  ru: RU,
  th: TH,
};
export default messages;
