import { createStore } from 'vuex';
import mutations from './locationMutations';
import actions from './locationActions';
import createPersistedState from 'vuex-persistedstate';

const locationStore = {
  state: {
    locationName: '',
  },
  mutations,
  actions,
};
export default locationStore;
