
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  unref,
} from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { gaEvent } from "@/utils/format";

export default defineComponent({
  components: {},

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Unbind Device",
    },
    width: {
      type: String,
    },
    info: {
      type: String,
    },
    Cancel: {
      type: String,
      default: "pop.cancel",
    },
    Okay: {
      type: String,
      default: "Confirm",
    },
    showBtn: {
      type: String,
      default: true,
    },
    showclose: {
      type: String,
      default: false,
    },
    // 如果是解绑来使用该组件，则要给close事件单独处理
    // 解绑使用为true，其他使用为false
    user: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cancle", "confirm", "unbindCancle"],
  setup(props, context) {
    const { t } = useI18n();
    const store = useStore();
    const visible = computed(() => props.visible);

    const handleClose = () => {
      if (props.user) {
        context.emit("unbindCancle", false);
      } else {
        context.emit("cancle", false);
      }
    };

    const clickClose = () => {
      context.emit("cancle", false);
    };

    const submit = () => {
      context.emit("confirm", false);
    };

    return {
      visible,
      submit,
      handleClose,
      clickClose,
    };
  },
});
